import React, { useContext, useState, forwardRef, useEffect } from "react";
import {
  AppBar,
  Box,
  Card,
  CardMedia,
  Toolbar,
  Typography,
  Dialog,
  Slide,
  Tabs,
  Tab,
  ThemeProvider,
  Container,
  Paper,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Color from "../constants/colors.json";
import UserContext from "../Contexts/UserContext";
import CompanyContext from "../Contexts/CompanyContext";
import Language from "../constants/language_fr.json";
import Profile from "./Profile";
import TeamManagement from "./TeamManagement";
// import UsersParameters from "./UsersParameters";
import ConcessionParams from "./concessionParams";
import CompanyManagement from "./companyManagement";
import LokaActivityIndicator from "./LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LokaSnackBar from "../components/LokaSnackBar";
import SnackBarContext from "../Contexts/SnackBarContext";
import Category from "../api/category";
import User from "../api/user";
import Booking from "../api/booking";
import DialogBody from "./TabsBar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ButtonAppBar(props) {
  const { setUser, user } = useContext(UserContext);
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const [isUserDetailOpen, setIsUserDetailOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [bookingRestrictions, setBookingRestrictions] = useState([]);
  const [members, setMembers] = useState([]);
  const [localData, setLocalData] = useState({
    members: [],
    bookingRestrictions: [],
    requiredFields: [],
    categories: [],
  });
  const [localProps, setLocalProps] = useState({
    isLoading: true,
  });

  const constructor = async () => {
    const { token } = user || {};
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { GetAllCategories, ConfigCategory } = Category || {};
    const { GetByCompany, GetAllUsers } = User || {};
    const { ConfigBooking } = Booking || {};

    const { customer } = await ConfigBooking({
      token: token,
    });
    setBookingRestrictions(customer?.items);
    let { data: _members, status } = await GetByCompany({ token });
    const _categories = await GetAllCategories({ token });
    const { machine, machineFeatures } = await ConfigCategory({ token });
    const _requiredFields = [...machine.items, ...machineFeatures.items].map(
      ({ key, label }) => {
        return { key, label };
      }
    );

    setRequiredFields(_requiredFields);
    setCategories(_categories);
    if (status == 200) {
      const activeMembersList = _members.filter(({ isActive }) => isActive);
      setLocalData({ ...localData, members: activeMembersList });
    }
    setLocalProps({
      ...localProps,
      isLoading: false,
    });
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };
  useEffect(() => {
    constructor();
  }, []);
  const logOut = () => {
    setUser(null);
    localStorage.removeItem("user");
  };
  return (
    <Box sx={{ flex: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Card sx={{ maxWidth: 150 }}>
              <CardMedia
                component="img"
                //height="40"
                width="100"
                image="https://loka-prod.s3.eu-west-3.amazonaws.com/CONFIGURATION/logo_600_1500.jpg"
                alt="logo LOKA"
              />
            </Card>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => logOut()}
            >
              <LogoutIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="large"
              aria-label="menu"
              onClick={() => {
                setIsUserDetailOpen(true);
              }}
            >
              <Typography
                variant="h6"
                component="div"
                noWrap
                sx={{ marginRight: 5 }}
              >
                {`${user?.lastName} ${user?.firstName}`}
              </Typography>
            </IconButton>
          </Box>
        </Toolbar>

        <DialogBody
          Transition={Transition}
          categories={categories}
          requiredFields={requiredFields}
          members={members}
          localData={localData}
          isUserDetailOpen={isUserDetailOpen}
          setIsUserDetailOpen={setIsUserDetailOpen}
          bookingRestrictions={bookingRestrictions}
        />

        <LokaSnackBar />
        <LokaActivityIndicator />
      </AppBar>
    </Box>
  );
}

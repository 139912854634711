import Axios from "axios";
const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
const machine = {
  Config: async ({ category, token }) => {
    const endPoint = "/config";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response || {};
  },
  ConfigKeyLabelConverter: async ({ category, token }) => {
    const endPoint = "/config/keyLabelConverter";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response || {};
  },
  ConfigKeyUnityConverter: async ({ category, token }) => {
    const endPoint = "/config/keyUnityConverter";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response || {};
  },
  DeleteById: async ({ idMachine, token }) => {
    const endPoint = "/machine/deleteById";
    const { data } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        idMachine,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response || {};
  },
  GetByCompanyByCategory: async ({ category, token }) => {
    const endPoint = "/machine/getByCompanyByCategory";
    const response = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category: category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return response;
  },
  GetGridCatalog: async ({ token }) => {
    const endPoint = "/machine/getGridCatalog";
    const response = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {},
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return response;
  },
  SwitchBookability: async ({ idMachine, token }) => {
    const endPoint = "/machine/switchBookability";
    const response = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        idMachine: idMachine,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return response;
  },
  importFromXls: async ({ formData, token }) => {
    const baseURL = `${serverUrl}/machine/xlsToStock`;
    const axiosParams = {
      method: "post",
      baseURL,
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
      config: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await Axios(axiosParams);

    return data || {};
  },
  updateById: async ({ body, token }) => {
    const endPoint = "/machine/updateById";
    const { updatedAt, ...rest } = body;
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        ...rest,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return { response: data?.response, status } || {};
  },
  getById: async ({ idMachine, token }) => {
    const endPoint = "/machine/getById";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idMachine,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response || {};
  },
  create: async ({ body, token }) => {
    const endPoint = "/machine/create";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: body,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return { response: data?.response, status } || {};
  },
};

export default machine;

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CloseIcon from "@mui/icons-material/Close";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import Moment from "moment";
import "moment/locale/fr";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Booking from "../api/booking";
import Machine from "../api/machine";
import User from "../api/user";
import {
  DateRenderer,
  UserCheckingRenderer,
} from "../components/AgGrid/CellRenderer";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import AgGridLocale from "./AgGrid/AG_GRID_LOCALE_FR.json";
import "./AgGrid/style.css";

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENCE_KEY}`);
const CheckingHistory = ({
  open,
  handleClose,
  contractsByMachine,
  selectedMachine,
  user,
  members,
}) => {
  const [contractDetails, setContractDetails] = useState(null);
  const [keyLabelConverter, setKeyLabelConverter] = useState(null);
  const [keyUnityConverter, setKeyUnityConverter] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const defaultColDef = {
    width: "20px",
    //flex: 1,
  };
  const columnDefs = [
    {
      field: "customer.name",
      headerName: Language.headerGrid.customerName,
    },
    {
      field: "createdBy",
      headerName: Language.headerGrid.userTitle,
      cellRenderer: "userCheckingRenderer",
      cellRendererParams: {
        members: members,
      },
      //minWidth: 40,
    },
    {
      field: "createdAt",
      headerName: Language.headerGrid.checkingDate,
      cellRenderer: "dateRenderer",
      // minWidth: 40,
    },
  ];
  const onFirstDataRendered = (params) => {
    /**Display data on full width */
    params.api.sizeColumnsToFit();
  };
  const getUserById = async ({ idUser }) => {
    const { GetById } = User;
    const { token } = user || {};
    const { firstName, lastName } = await GetById({
      idUser,
      token,
    });
    return `${firstName} ${lastName}`;
  };
  const onRowClicked = async ({ data }) => {
    setCurrentIndex(0);
    const { ConfigBookingKeyLabelConverter } = Booking || {};
    const { ConfigKeyLabelConverter, ConfigKeyUnityConverter } = Machine || {};
    const { token } = user || {};
    const { category } = data?.machine?.machine || {};
    const _bookingConfigKeyLabelConverter =
      await ConfigBookingKeyLabelConverter({
        category,
        token,
      });
    const _configKeyLabelConverter = await ConfigKeyLabelConverter({
      category,
      token,
    });
    const _configKeyUnityConverter = await ConfigKeyUnityConverter({
      category,
      token,
    });
    const _keyLabelConverter = {
      ..._configKeyLabelConverter,
      ..._bookingConfigKeyLabelConverter,
    };
    const createdByStartChecking = data?.contractsToStartCheckings
      ? await getUserById({
          idUser: data?.contractsToStartCheckings?.createdBy,
        })
      : null;
    const createdByStopChecking = data?.contractsToStopCheckings
      ? await getUserById({ idUser: data?.contractsToStopCheckings?.createdBy })
      : null;
    setKeyUnityConverter(_configKeyUnityConverter);
    setKeyLabelConverter(_keyLabelConverter);
    setContractDetails({
      ...data,
      createdByStartChecking,
      createdByStopChecking,
    });
  };
  const displayImage = ({ url }) => {
    return (
      <Box justifyContent="center" alignItems="center">
        {url ? (
          <img height="300" src={url} />
        ) : (
          <div style={{ textAlign: "center" }}>
            <ImageNotSupportedIcon
              fontSize="large"
              style={{ color: Colors.grey }}
            />
            <Typography
              style={{ textAlign: "center" }}
              color={Colors.greyShaded}
            >
              {Language?.catalog?.noInfo}
            </Typography>
          </div>
        )}
      </Box>
    );
  };
  const {
    contractsToStartCheckings,
    contractsToStopCheckings,
    createdByStartChecking,
    createdByStopChecking,
  } = contractDetails || {};

  const goToSelectedKey = (value) => {
    setCurrentIndex(value);
  };
  const muiTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "white",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRadius: "3px",
            minHeight: "15px",
            "&:hover": {
              backgroundColor: "#E6F6E0",
            },
            "&.Mui-selected": {
              color: Colors.white,
              backgroundColor: Colors.green,
              border: `0.5px solid ${Colors.green}`,

              "&:hover": {
                //color: Colors.green,
                color: Colors.white,
              },
            },
          },
        },
      },
    },
  });

  const CheckingDetails = ({ startChecking, stopChecking, currentIndex }) => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    const checkingExceptions = ["signature"];
    const _checkingKeys = startChecking ? Object.keys(startChecking) : [];
    const _urlStartCheckings = _checkingKeys.filter((key) =>
      String(startChecking[key].value).match(regex)
    );
    const _stopcheckingKeys = stopChecking ? Object.keys(stopChecking) : [];
    const _urlStopCheckings = _stopcheckingKeys.filter((key) =>
      String(stopChecking[key].value).match(regex)
    );
    const _allUrlCheckings = [..._urlStartCheckings, ..._urlStopCheckings];
    const _urlCheckings = _allUrlCheckings.filter(
      (item, index) => _allUrlCheckings.indexOf(item) === index
    );
    const _textStartCheckings = _checkingKeys.filter(
      (key) =>
        !String(startChecking[key].value).match(regex) &&
        !checkingExceptions.includes(key)
    );
    const _textStopCheckings = _stopcheckingKeys.filter(
      (key) =>
        !String(stopChecking[key].value).match(regex) &&
        !checkingExceptions.includes(key)
    );
    const _allTextCheckings = [..._textStartCheckings, ..._textStopCheckings];

    // const _allTextCheckings = [..._checkingKeys, ..._stopcheckingKeys].filter(
    //   (key) =>
    //     !String(startChecking[key].value).match(regex) &&
    //     !String(stopChecking[key].value).match(regex)
    // );

    const _textCheckings = _allTextCheckings.filter(
      (item, index) =>
        _allTextCheckings.indexOf(item) === index &&
        item != "createdAt" &&
        item != "createdBy"
    );
    return _checkingKeys?.length || _stopcheckingKeys?.length ? (
      <>
        {_urlCheckings?.length ? (
          <>
            <Box
              style={{
                flex: 1,

                display: "flex",
                alignItems: "center",

                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: { xs: 300, sm: 600, md: 700, lg: 900 },

                  bgcolor: "background.paper",
                }}
              >
                <ThemeProvider theme={muiTheme}>
                  <Tabs
                    value={currentIndex}
                    onChange={(e, value) => goToSelectedKey(value)}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {_urlCheckings.map((key) => (
                      <Tab label={keyLabelConverter[key]} />
                    ))}
                  </Tabs>
                </ThemeProvider>
              </Box>
            </Box>
            <Grid container direction="row">
              <Grid
                container
                item
                xs={6}
                lg={6}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {_checkingKeys?.length ? (
                  <SwipeableViews
                    axis={"x"}
                    enableMouseEvents
                    id="swipeableViews"
                  >
                    {displayImage({
                      url: startChecking[_urlCheckings[currentIndex]]?.value,
                    })}
                  </SwipeableViews>
                ) : (
                  <Box
                    height={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Typography
                      style={{ textAlign: "center" }}
                      color={Colors.greyShaded}
                    >
                      {Language?.contractDetails?.noChecking}
                      <Typography
                        textAlign={"center"}
                        color={Colors.greyShaded}
                      >
                        {Language?.catalog?.enterChecking}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                xs={6}
                lg={6}
              >
                {_stopcheckingKeys?.length ? (
                  <SwipeableViews
                    axis={"x"}
                    enableMouseEvents
                    id="swipeableViews"
                  >
                    {displayImage({
                      url: stopChecking[_urlCheckings[currentIndex]]?.value,
                    })}
                  </SwipeableViews>
                ) : (
                  <Box
                    height={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Typography
                      style={{ textAlign: "center" }}
                      color={Colors.greyShaded}
                    >
                      {Language?.contractDetails?.noChecking}
                      <Typography
                        textAlign={"center"}
                        color={Colors.greyShaded}
                      >
                        {Language?.catalog?.enterChecking}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        ) : null}

        <div
          style={{
            marginTop: "10px",
            overflowY: "scroll",
            height: 110,
          }}
        >
          {_textCheckings &&
            _textCheckings.map((key) => {
              const { unity } =
                keyUnityConverter.find((item) => item.key === key) || {};

              return (
                <Grid container direction="row" alignItems={"center"}>
                  <Grid container item xs={2} lg={2}>
                    <Typography
                      fontWeight="bold"
                      color={Colors.grey}
                    >{`${keyLabelConverter[key]}`}</Typography>
                  </Grid>

                  <Grid container item xs={5} lg={5}>
                    {_checkingKeys?.length ? (
                      <Typography
                        color={
                          startChecking[key]?.value
                            ? Colors.grey
                            : Colors.greyShaded
                        }
                      >
                        {startChecking[key]?.value != undefined
                          ? `${startChecking[key]?.value} ${unity ? unity : ""}`
                          : Language?.catalog?.noValue}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid container item xs={5} lg={5}>
                    {_stopcheckingKeys?.length ? (
                      <Typography
                        color={
                          stopChecking[key]?.value != undefined
                            ? Colors.grey
                            : Colors.greyShaded
                        }
                      >
                        {stopChecking[key]?.value
                          ? `${stopChecking[key]?.value} ${unity ? unity : ""}`
                          : Language?.catalog?.noValue}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      </>
    ) : null;
  };
  const { category, brand, model } = selectedMachine?.machine || {};

  return (
    <Dialog fullScreen open={open}>
      <Box
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {`${Language?.catalog?.history} ${Language.categoryConverter[category]} ${brand} ${model}`}
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            className="z-index:9"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <div id="grid-theme-wrapper" className="green-theme">
              <div
                className="ag-theme-alpine"
                style={{
                  width: "100%",
                  height: "90vh",
                  //paddingTop: 5,
                }}
              >
                <AgGridReact
                  zIndex={-1}
                  localeText={AgGridLocale}
                  rowData={contractsByMachine}
                  defaultColDef={defaultColDef}
                  rowSelection={"single"}
                  rowHeight={50}
                  onFirstDataRendered={onFirstDataRendered}
                  columnDefs={columnDefs}
                  frameworkComponents={{
                    dateRenderer: DateRenderer,
                    userCheckingRenderer: UserCheckingRenderer,
                  }}
                  onRowClicked={async (event) =>
                    onRowClicked({ data: event?.data })
                  }
                />
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 2,
              borderLeft: "1px solid lightgrey",
            }}
          >
            {contractDetails ? (
              <>
                <Typography
                  fontWeight="bold"
                  style={{ padding: "5px", color: Colors.grey }}
                >
                  {Language?.catalog?.checking}
                </Typography>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    {contractsToStartCheckings && (
                      <>
                        <Box display="flex" alignItems={"center"}>
                          <PersonIcon style={{ color: Colors.grey }} />
                          <Typography
                            fontSize={12}
                            style={{ color: Colors.grey }}
                          >
                            {createdByStartChecking}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems={"center"}>
                          <CalendarMonthIcon style={{ color: Colors.grey }} />
                          <Typography
                            fontSize={12}
                            style={{ color: Colors.grey }}
                          >
                            {Moment(
                              contractsToStartCheckings?.createdAt
                            ).format("DD/MM/YYYY à HH:mm")}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    {contractsToStopCheckings && (
                      <>
                        <Box display="flex" alignItems={"center"}>
                          <PersonIcon style={{ color: Colors.grey }} />
                          <Typography
                            fontSize={12}
                            style={{ color: Colors.grey }}
                          >
                            {createdByStopChecking}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems={"center"}>
                          <CalendarMonthIcon style={{ color: Colors.grey }} />
                          <Typography
                            fontSize={12}
                            style={{ color: Colors.grey }}
                          >
                            {Moment(contractsToStopCheckings?.createdAt).format(
                              "DD/MM/YYYY à HH:mm"
                            )}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
                <Divider style={{ border: "1px solid lightgrey" }} />

                {contractsToStartCheckings || contractsToStopCheckings ? (
                  <CheckingDetails
                    startChecking={contractsToStartCheckings || {}}
                    stopChecking={contractsToStopCheckings || {}}
                    currentIndex={currentIndex}
                  />
                ) : (
                  <Box
                    height={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Typography
                      style={{ textAlign: "center" }}
                      color={Colors.greyShaded}
                    >
                      {Language?.contractDetails?.noChecking}
                      <Typography
                        textAlign={"center"}
                        color={Colors.greyShaded}
                      >
                        {Language?.catalog?.enterChecking}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                height={"100%"}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography
                  style={{
                    textAlign: "center",

                    padding: "10px",
                  }}
                  color={Colors.greyShaded}
                >
                  {Language?.catalog?.selectContract}
                </Typography>
              </Box>
            )}
            <Grid
              container
              direction="row"
              style={{
                position: "absolute",

                bottom: 0,

                width: "60%",
              }}
            >
              <Grid
                container
                item
                xs={5}
                lg={5}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  textAlign={"center"}
                  padding={"3px"}
                  color={Colors.greyShaded}
                >
                  {Language?.catalog?.checkin}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={5}
                lg={5}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  textAlign={"center"}
                  padding={"3px"}
                  color={Colors.greyShaded}
                >
                  {Language?.catalog?.checkout}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};
export default CheckingHistory;

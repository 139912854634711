import ListAltIcon from "@mui/icons-material/ListAlt";
import SaveIcon from "@mui/icons-material/Save";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    FormControl,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import Company from "../../api/company";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
import Styles from "../../constants/styles.json";

export default function AddDefaultCategories({
  company,
  expanded,
  setExpanded,
  setSnackBar,
  setCompany,
  setActivityIndicator,
  user,
}) {
  const { groupList = [] } = company.config;

  const [form, setForm] = useState("");

  const handleChange = ({ target }) => {
    const { value } = target;
    const _value = value.replace(/[^a-zA-Z0-9-_]/g, "");
    setForm(_value);
  };

  const handleSave = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.updateCompanyInProgress,
    });

    const { Update } = Company || {};
    const _groupList = [...groupList, form];
    const _config = { ...company.config, groupList: _groupList };
    const _company = {
      ...company,
      config: _config,
    };
    try {
      const { status } = await Update({
        company: _company,
        token: user.token,
      });

      if (status === 200) {
        _company && setCompany(_company);
        _company && localStorage.setItem("company", JSON.stringify(_company));
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.updateCompanySuccess,
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.updateCompanyError,
          status: "error",
        });
      }
      setActivityIndicator({ isOpen: false, message: null });
      setForm("");
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanyError,
        status: "error",
      });
      setActivityIndicator({ isOpen: false, message: null });
    }
  };

  const isDisabled = groupList.includes(form);

  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "groupList"}
      onChange={() =>
        expanded === "groupList" ? setExpanded(null) : setExpanded("groupList")
      }
    >
      <AccordionSummary
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListAltIcon style={{ color: Color.green }} />
        <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
          {Language.appBar.manageCompanyGroupList}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl key="groupList">
          <Box flexWrap="wrap">
            {groupList.map((g) => (
              <Chip
                label={g}
                style={{
                  margin: 10,
                }}
              />
            ))}
          </Box>
          <TextField
            sx={{ width: "100%", marginTop: "15px" }}
            label={Language.appBar.addGroup}
            onChange={handleChange}
            value={form}
          />
          <Button
            style={Styles.button}
            disabled={isDisabled}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.add}
          </Button>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

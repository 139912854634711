import React from "react";
import Colors from "../constants/colors.json";
import { Typography } from "@mui/material";

const LokaChipInput = ({ chipList, setValue, value }) => {
  const handleClick = ({ chip }) => {
    const _value = value?.includes(chip)
      ? value?.filter((item) => item !== chip)
      : [...value, chip];
    setValue(_value);
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        padding: 10,
        flexDirection: "row",
        gap: 5,
        justifyContent: "flex-start",
      }}
    >
      {chipList?.map((chip) => {
        const isSelected = value?.includes(chip);
        return (
          <div
            onClick={() => handleClick({ chip })}
            style={
              isSelected
                ? {
                    cursor: "pointer",
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: Colors.green,
                  }
                : {
                    cursor: "pointer",
                    padding: 5,
                    borderRadius: 5,
                    border: 1,
                    borderColor: Colors.greyHighShaded,
                    borderStyle: "solid",
                  }
            }
          >
            <Typography
              sx={
                isSelected
                  ? { fontSize: 12, color: Colors.white }
                  : { fontSize: 12, color: Colors.greyShaded }
              }
            >
              {chip}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
export default LokaChipInput;

import React, { useState } from "react";

// core
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  TextField,
  Grid,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//icons
import SaveIcon from "@mui/icons-material/Save";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Color from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import Styles from "../constants/styles.json";
//api
import Company from "../api/company";
import User from "../api/user";
export default function CompanyManagement({
  companyInfo,
  userInfo,
  setSnackBar,
  setActivityIndicator,
}) {
  const [idUser, setIdUser] = useState("");
  const { Create: CreateUser, DeleteById } = User || {};
  const { token } = userInfo;
  const {
    appBar: {
      dealershipId,
      firstName,
      lastName,
      userEmail,
      userLicence,
      userPhoneNumber,
      companyName,
      companyAddress,
      companyPostal,
      companyCity,
      companyRegistrationNumber,
      companyUsersNumber,
      userIdLabel,
    },
    yupSchemaError: {
      companyNameRequired,
      addressRequired,
      postalCodeRequired,
      cityRequired,
      legalNumberRequired,
      usersAvailableRequired,
      idCompanyRequired,
      firstNameRequired,
      lastNameRequired,
      emailRequired,
      licenceRequired,
      phoneNumberRequired,
      emailValid,
    },
  } = Language || {};
  const companySchema = yup.object().shape({
    name: yup.string().required(companyNameRequired),
    address: yup.string().required(addressRequired),
    postCode: yup.string().required(postalCodeRequired),
    city: yup.string().required(cityRequired),
    legalNumber: yup.string().required(legalNumberRequired),
    usersAvailable: yup.string().required(usersAvailableRequired),
  });
  const userSchema = yup.object().shape({
    idCompany: yup.string().required(idCompanyRequired),
    firstName: yup.string().required(firstNameRequired),
    lastName: yup.string().required(lastNameRequired),
    email: yup.string().email(emailValid).required(emailRequired),
    licence: yup.string().required(licenceRequired),
    cellPhone: yup.string().required(phoneNumberRequired),
  });
  const {
    handleSubmit: companySubmit,
    formState: { errors: companyErrors, isValid: isCompanyValid },
    control: companyControl,
  } = useForm({
    resolver: yupResolver(companySchema),
    mode: "onChange",
  });
  const {
    handleSubmit: userSubmit,
    formState: { errors: userErrors, isValid: isUserValid },
    control: userControl,
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: "onChange",
  });

  const createNewCompany = async (data) => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.addCompanyInProgress,
    });
    try {
      const { Create } = Company || {};
      const { status } = await Create({
        company: data,
        token,
      });

      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.addCompanySuccess,
          status: "success",
        });
      }
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.addCompanyError,
        status: "error",
      });
    }
  };
  const createNewUser = async (data) => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.addUserInProgress,
    });
    try {
      const status = await CreateUser({
        user: data,
        token,
      });
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.addUserSuccess,
          status: "success",
        });
      }
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.addUserError,
        status: "error",
      });
    } finally {
      setActivityIndicator({ isOpen: false, message: null });
    }
  };

  const deleteUser = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.deleteUserInProgress,
    });
    try {
      const status = await DeleteById({ idUser, token });
      if (status === 200) {
        setIdUser("");
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.deleteUserSuccess,
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.deleteUserError,
          status: "error",
        });
      }
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.deleteUserError,
        status: "error",
      });
    } finally {
      setActivityIndicator({ isOpen: false, message: null });
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        align="center"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "25px" }}
      >
        <Grid item xs={6} lg={6}>
          <Accordion>
            <AccordionSummary style={{ display: "flex", alignItems: "center" }}>
              <CreateNewFolderIcon style={{ color: Color.green }} />
              <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
                {Language.appBar.addDealership}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl style={{ width: "100%" }}>
                {[
                  { title: companyName, key: "name" },
                  { title: companyAddress, key: "address" },
                  { title: companyPostal, key: "postCode" },
                  { title: companyCity, key: "city" },
                  {
                    title: companyRegistrationNumber,
                    key: "legalNumber",
                  },
                  {
                    title: companyUsersNumber,
                    key: "usersAvailable",
                  },
                ].map(({ title, key }) => (
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        key={key}
                        sx={{ width: "100%", marginTop: "15px" }}
                        label={title}
                        onChange={onChange}
                        value={value}
                        error={companyErrors[key]?.message ? true : false}
                        helperText={companyErrors[key]?.message}
                      />
                    )}
                    key={key}
                    name={key}
                    control={companyControl}
                    defaultValue=""
                  />
                ))}
                <Button
                  style={Styles.button}
                  variant="contained"
                  color="primary"
                  disabled={!isCompanyValid}
                  onClick={companySubmit(createNewCompany)}
                  startIcon={<SaveIcon />}
                >
                  {Language.appBar.add}
                </Button>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <PersonAddIcon style={{ color: Color.green }} />
              <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
                {Language.appBar.addAUser}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl style={{ width: "100%" }}>
                {[
                  { title: dealershipId, key: "idCompany" },
                  { title: firstName, key: "firstName" },
                  { title: lastName, key: "lastName" },
                  { title: userEmail, key: "email" },
                  { title: userLicence, key: "licence" },
                  {
                    title: userPhoneNumber,
                    key: "cellPhone",
                  },
                ].map(({ title, key }) => (
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        key={key}
                        sx={{ width: "100%", marginTop: "15px" }}
                        label={title}
                        onChange={onChange}
                        value={value}
                        error={userErrors[key]?.message ? true : false}
                        helperText={userErrors[key]?.message}
                      />
                    )}
                    key={key}
                    name={key}
                    control={userControl}
                    defaultValue=""
                  />
                ))}
                <Button
                  style={Styles.button}
                  variant="contained"
                  color="primary"
                  disabled={!isUserValid}
                  onClick={userSubmit(createNewUser)}
                  startIcon={<SaveIcon />}
                >
                  {Language.appBar.add}
                </Button>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <PersonRemoveIcon style={{ color: Color.green }} />
              <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
                {Language.appBar.deleteAUser}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl style={{ width: "100%" }}>
                {[{ title: userIdLabel, key: "idUser" }].map((element) => (
                  <TextField
                    key={element.title}
                    sx={{ width: "100%", marginTop: "15px" }}
                    variant="outlined"
                    label={element.title}
                    onChange={(event) => setIdUser(event.target.value)}
                    value={idUser}
                  />
                ))}
                <Button
                  style={Styles.button}
                  disabled={idUser == ""}
                  variant="contained"
                  color="primary"
                  onClick={() => deleteUser()}
                  startIcon={<SaveIcon />}
                >
                  {Language.appBar.delete}
                </Button>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}

import Axios from "axios";

const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
const category = {
  GetAllCategories: async ({ token }) => {
    const endPoint = "/category";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return data?.response || [];
  },
  GetByCompany: async ({ defaultCategories, token }) => {
    const endPoint = "/category/getByCompany";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        defaultCategories: defaultCategories,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || [];
  },
  ConfigCategory: async ({ token }) => {
    const endPoint = "/config";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category: "tractor",
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || [];
  },
};

export default category;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ===               === */
/*                       */
/*       GREEN THEME     */
/*                       */
/* ===               === */

/* ROW SELECTION */
#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-row-selected {
    background-color: #55A932 !important;
    color: rgb(255, 255, 255);
  
}
#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-row-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: #E6F6E0 ;
} 
/* RANGE SELECTION */
/* 
#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-cell-range-selected:not(.ag-cell-focus),
#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
    background-color:  #55A932;
} */
`, "",{"version":3,"sources":["webpack://./src/components/AgGrid/style.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B,0BAA0B;AAC1B,0BAA0B;AAC1B,0BAA0B;AAC1B,0BAA0B;;AAE1B,kBAAkB;AAClB;IACI,oCAAoC;IACpC,yBAAyB;;AAE7B;AACA;IACI,wFAAwF;IACxF,0BAA0B;AAC9B;AACA,oBAAoB;AACpB;;;;GAIG","sourcesContent":["/* ===               === */\n/*                       */\n/*       GREEN THEME     */\n/*                       */\n/* ===               === */\n\n/* ROW SELECTION */\n#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-row-selected {\n    background-color: #55A932 !important;\n    color: rgb(255, 255, 255);\n  \n}\n#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-row-hover {\n    /* putting in !important so it overrides the theme's styling as it hovers the row also */\n    background-color: #E6F6E0 ;\n} \n/* RANGE SELECTION */\n/* \n#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-cell-range-selected:not(.ag-cell-focus),\n#grid-theme-wrapper.green-theme .ag-theme-alpine .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {\n    background-color:  #55A932;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

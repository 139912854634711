import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import CompanyContext from "../../Contexts/CompanyContext";
import UserContext from "../../Contexts/UserContext";
import AddAgency from "./addAgency";
import AddDefaultCategories from "./addDefaultCategories";
import AddRequiredFields from "./addRequiredFields";
import ApiConnexions from "./apiConnexions";
import CompanyGroupList from "./CompanyGroupList";
import ImageSize from "./imageSize";
export default function ConcessionParams({
  companyInfo,
  userInfo,
  setSnackBar,
  // expanded,
  // setExpanded,
  logInfo,
  setLoader,
  getLogInfo,
  setSnackbar,
  successSnackBar,
  failedSnackBar,
  setIsUserDetailOpen,
  setDisableSaveUser,
  disableSaveUser,
  setActivityIndicator,
  categories,
  requiredFields,
}) {
  const { company, setCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const [expanded, setExpanded] = useState(null);
  const [localProps, setLocalProps] = useState({
    isLoading: true,
  });
  return (
    <Grid
      container
      direction="row"
      align="center"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "25px" }}
    >
      <Grid item xs={9} lg={9}>
        <AddAgency
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          logInfo={logInfo}
          setLoader={setLoader}
          getLogInfo={getLogInfo}
          setSnackbar={setSnackbar}
          successSnackBar={successSnackBar}
          failedSnackBar={failedSnackBar}
          setIsUserDetailOpen={setIsUserDetailOpen}
          setSnackBar={(props) => setSnackBar(props)}
        />
        <ImageSize
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
        />
        <AddDefaultCategories
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          categories={categories}
        />
        <CompanyGroupList
          company={company}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          user={user}
        />
        <AddRequiredFields
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          requiredFieldsList={requiredFields}
        />
        <ApiConnexions
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
        />
      </Grid>
    </Grid>
  );
}

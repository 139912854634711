import { ButtonGroup, Chip, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Colors from "../constants/colors.json";

const Selector = (props) => {
  const { defaultList, multiple, selectorList, setSelectorList } = props || {};

  const [localProps, setLocalProps] = useState({ isLoading: true });

  const constructor = async () => {
    setLocalProps({
      isLoading: false,
    });
  };

  const { isLoading } = localProps || {};

  isLoading && constructor();

  const handleToggleGroup = (group) => {
    const _isGroupAlreadySelected = selectorList.includes(group);
    const _selectorList = _isGroupAlreadySelected
      ? selectorList.filter((g) => g !== group)
      : [...selectorList, group];
    multiple ? setSelectorList(_selectorList) : setSelectorList([group]);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <ButtonGroup
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {defaultList?.length
          ? defaultList.map((group, index) => {
              const _isSelected = selectorList.includes(group);
              return (
                <Chip
                  style={{
                    backgroundColor: _isSelected ? Colors.green : Colors.white,
                    color: _isSelected ? Colors.white : Colors.grayText,
                    margin: 5,
                  }}
                  key={index}
                  label={group}
                  variant="outlined"
                  onClick={() => handleToggleGroup(group)}
                />
              );
            })
          : null}
      </ButtonGroup>
    </>
  );
};
export default Selector;

import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import Moment from "moment";
import "moment/locale/fr";
import { useContext, useState } from "react";
import User from "../../api/user";
import Colors from "../../constants/colors.json";
import dataConfig from "../../constants/data.json";
import Language from "../../constants/language_fr.json";
import SnackBarContext from "../../Contexts/SnackBarContext";
import UserContext from "../../Contexts/UserContext";
const CategoryRenderer = ({ data }) => {
  const { category } = data || {};
  return <div>{Language.categoryConverter[category] || category}</div>;
};

const MainImage = ({ data }) => {
  const { mainPicture } = data || {};
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={50}>
      {mainPicture ? (
        <img height="100%" src={mainPicture} alt="machine miniature" />
      ) : (
        <Typography fontSize={12} fontStyle="italic" color={Colors.greyShaded}>
          {null}
        </Typography>
      )}
    </Box>
  );
};

const UserRenderer = ({ data: { isConfirmed, firstName, lastName } }) => {
  const _style = isConfirmed
    ? { textAlign: "center" }
    : { textAlign: "center", fontSize: 12, fontStyle: "italic" };
  return (
    <div style={_style}>
      {isConfirmed
        ? `${firstName} ${lastName}`
        : `${firstName} ${lastName} (en attente)`}
    </div>
  );
};
const LicenceRender = (params) => {
  const { user, setUser } = useContext(UserContext);
  const { setSnackBar } = useContext(SnackBarContext);

  const { setValue, value: licence, data } = params;
  const handleChange = async (event) => {
    const {
      target: { value: selectedLicence },
    } = event;
    setValue(selectedLicence);
    const { Update } = User || {};
    const { token, idCompany } = user || {};

    try {
      const { status } = await Update({
        user: {
          ...data,
          licence: selectedLicence,
          idCompany,
        },
        token,
      });

      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "success",
        });
        const _updatedUser = { ...user, licence: selectedLicence };
        setUser(_updatedUser);
        localStorage.setItem("user", JSON.stringify(_updatedUser));
      } else {
        setValue(data?.licence);
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberError,
          status: "error",
        });
      }
    } catch (error) {
      setValue(data?.licence);
      setSnackBar({
        isOpen: true,
        message: Language.cellRenderer.updateMemberError,
        status: "error",
      });
    }
  };
  return (
    <FormControl
      style={{
        width: "100%",
        flex: 1,
      }}
      sx={{ m: 1, minWidth: 120 }}
      size="small"
    >
      <InputLabel>{Language.appBar.userLicence}</InputLabel>
      <Select
        value={licence}
        renderValue={(selected) => {
          return `${selected}`;
        }}
        onChange={handleChange}
        input={<OutlinedInput label={Language.appBar.userLicence} />}
      >
        {dataConfig?.licences.map((currentLicence) => (
          <MenuItem key={currentLicence} value={currentLicence}>
            <Checkbox checked={licence === currentLicence} />
            <ListItemText
              primaryTypographyProps={{ fontSize: 15 }}
              primary={currentLicence}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const AgGridMultiSelect = ({
  data,
  formatedData,
  label,
  itemTextObject,
  path,
  params,
}) => {
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [selectedValues, setSelectedValues] = useState(formatedData);
  const { user } = useContext(UserContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsUpdateUser(true);
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };
  const updateProfileUser = async () => {
    const { setValue, data: rowdata } = params;
    const { Update } = User || {};
    const { token, idCompany } = user || {};
    const updated_object = Object.keys(data).reduce((acc, value) => {
      const _result = {
        ...acc,
        [value]: selectedValues.includes(value) ? true : false,
      };
      return _result;
    }, {});
    try {
      const { status } =
        isUpdateUser &&
        (await Update({
          user: {
            ...rowdata,
            configuration: {
              ...rowdata.configuration,
              [path]: { ...updated_object },
            },
            idCompany,
          },
          token,
        }));
      isUpdateUser && setValue(updated_object);
      setIsUpdateUser(false);
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "success",
        });
      }
    } catch (error) {
      setSelectedValues(formatedData);
      setSnackBar({
        isOpen: true,
        message: Language.cellRenderer.updateMemberError,
        status: "error",
      });
    }
  };

  return (
    <FormControl
      style={{
        width: "100%",
        flex: 1,
      }}
      sx={{ m: 1, minWidth: 120 }}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedValues}
        onClose={() => updateProfileUser()}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        // MenuProps={MenuProps}
        multiple
        renderValue={(selected) => {
          return `${label} (${selected.length})`;
        }}
      >
        {Object.keys(data).map((element) => (
          <MenuItem key={element} value={element}>
            <Checkbox checked={selectedValues.includes(element)} />
            <ListItemText
              primaryTypographyProps={{ fontSize: 15 }}
              primary={itemTextObject[element]}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const EmailNotifRenderer = (params) => {
  const { emailNotifications } = dataConfig;
  const { value: _emailNotifications } = params;
  const emailNotificationList =
    _emailNotifications &&
    Object.keys(_emailNotifications)
      .map((emailNotifValue) => {
        return _emailNotifications[emailNotifValue] === true
          ? emailNotifValue
          : undefined;
      })
      .filter((item) => item !== undefined);
      
  return (
    <AgGridMultiSelect
      data={emailNotifications}
      formatedData={emailNotificationList || []}
      label={Language.emailNotifRenderer.notifications}
      itemTextObject={Language.emailNotifRenderer}
      params={params}
      path={"emailNotifications"}
    />
  );
};
const RestrictionRenderer = (params) => {
  const { restrictions } = dataConfig;
  const { value: _restrictions } = params;
  const restrictionList = Object.keys(_restrictions)
    .map((restrictionValue) => {
      return _restrictions[restrictionValue] === true
        ? restrictionValue
        : undefined;
    })
    .filter((item) => item != undefined);

  return (
    <AgGridMultiSelect
      data={restrictions}
      formatedData={restrictionList || []}
      label={Language.restrictionRender.restrictions}
      itemTextObject={Language.restrictionRender}
      params={params}
      path={"restrictions"}
    />
  );
};
const BookingRequirementRender = (params) => {
  const { data, value: booking, setValue, bookingRestrictions } = params;
  const { user } = useContext(UserContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const bookingRequirementList = Object.keys(booking)
    .map((element) => {
      return booking[element] === true ? element : undefined;
    })
    .filter((item) => item != undefined);

  const [selectedValues, setSelectedValues] = useState(bookingRequirementList);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const { bookingRequirementsLabel } = Language.requirementsRender || {};

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsUpdateUser(true);
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  const updateProfileUser = async () => {
    const { Update } = User || {};
    const { token, idCompany } = user || {};
    const _bookings = selectedValues.reduce((acc, selectedValue) => {
      const _result = {
        ...acc,
        [selectedValue]: true,
      };
      return _result;
    }, {});

    try {
      const { status } =
        isUpdateUser &&
        (await Update({
          user: {
            ...data,
            configuration: {
              ...data.configuration,
              requirements: { ...data.requirements, booking: _bookings },
            },
            idCompany,
          },
          token,
        }));
      setIsUpdateUser(false);
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "success",
        });
        setValue(_bookings);
      } else {
        setSelectedValues(bookingRequirementList);
      }
    } catch (err) {
      setSelectedValues(bookingRequirementList);
      setSnackBar({
        isOpen: true,
        message: Language.cellRenderer.updateMemberError,
        status: "error",
      });
    }
  };
  return (
    <FormControl
      style={{
        width: "100%",
        flex: 1,
      }}
      sx={{ m: 1, minWidth: 120 }}
      size="small"
    >
      <InputLabel>{bookingRequirementsLabel}</InputLabel>
      <Select
        value={selectedValues}
        onClose={() => updateProfileUser()}
        onChange={handleChange}
        input={<OutlinedInput label={bookingRequirementsLabel} />}
        // MenuProps={MenuProps}
        multiple
        renderValue={(selected) => {
          return `${bookingRequirementsLabel} (${selected.length})`;
        }}
      >
        {bookingRestrictions.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={selectedValues.includes(key)} />
            <ListItemText
              primaryTypographyProps={{ fontSize: 15 }}
              primary={label}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const CheckingRequirementRender = (params) => {
  const { data, value: checking, setValue, checkingRequirements } = params;
  const { user } = useContext(UserContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const checkingRequirementList = Object.keys(checking)
    .map((element) => {
      return checking[element] === true ? element : undefined;
    })
    .filter((item) => item != undefined);

  const [selectedValues, setSelectedValues] = useState(checkingRequirementList);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const { checkingRequirementsLabel } = Language.requirementsRender || {};

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsUpdateUser(true);
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  const updateProfileUser = async () => {
    const { Update } = User || {};
    const { token, idCompany } = user || {};
    const _checkings = selectedValues.reduce((acc, selectedValue) => {
      const _result = {
        ...acc,
        [selectedValue]: true,
      };
      return _result;
    }, {});

    try {
      const { status } =
        isUpdateUser &&
        (await Update({
          user: {
            ...data,
            configuration: {
              ...data.configuration,
              requirements: { ...data.requirements, checking: _checkings },
            },
            idCompany,
          },
          token,
        }));
      setIsUpdateUser(false);
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "success",
        });
        setValue(_checkings);
      } else {
        setSelectedValues(checkingRequirementList);
      }
    } catch (err) {
      setSelectedValues(checkingRequirementList);
      setSnackBar({
        isOpen: true,
        message: Language.cellRenderer.updateMemberError,
        status: "error",
      });
    }
  };
  return (
    <FormControl
      style={{
        width: "100%",
        flex: 1,
      }}
      sx={{ m: 1, minWidth: 120 }}
      size="small"
    >
      <InputLabel>{checkingRequirementsLabel}</InputLabel>
      <Select
        value={selectedValues}
        onClose={() => updateProfileUser()}
        onChange={handleChange}
        input={<OutlinedInput label={checkingRequirementsLabel} />}
        multiple
        renderValue={(selected) => {
          return `${checkingRequirementsLabel} (${selected.length})`;
        }}
      >
        {checkingRequirements
          .sort((a, b) => (a.label < b.label ? -1 : 1))
          .map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              <Checkbox checked={selectedValues.includes(key)} />
              <ListItemText
                primaryTypographyProps={{ fontSize: 15 }}
                primary={label}
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
const ActiveRenderer = (params) => {
  const { user } = useContext(UserContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const updateProfileUser = async ({ isActive }) => {
    const { Update } = User || {};
    const { token, idCompany } = user || {};
    try {
      const { status } = await Update({
        user: {
          ...params?.data,
          isActive,
          idCompany,
        },
        token,
      });

      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.cellRenderer.updateMemberSuccess,
          status: "error",
        });

        params.setValue(!isActive);
      }
    } catch (err) {
      setSnackBar({
        isOpen: true,
        message: Language.cellRenderer.updateMemberSuccess,
        status: "error",
      });

      params.setValue(!isActive);
    }
  };
  return (
    <Switch
      color="secondary"
      checked={params.value}
      onChange={() => {
        params.setValue(!params.value);
        updateProfileUser({
          isActive: !params.value,
        });
      }}
    />
  );
};
const DateRenderer = ({ value: date }) => {
  return date ? (
    <div style={{ textAlign: "left" }}>{Moment(date).format("DD/MM/YYYY")}</div>
  ) : (
    <></>
  );
};
const UserCheckingRenderer = ({ value: createdBy, members }) => {
  const { firstName, lastName } =
    members?.find((member) => member?.id == createdBy) || {};
  return <div>{`${firstName} ${lastName}`}</div>;
};

export {
  CategoryRenderer,
  MainImage,
  UserRenderer,
  LicenceRender,
  EmailNotifRenderer,
  RestrictionRenderer,
  BookingRequirementRender,
  CheckingRequirementRender,
  ActiveRenderer,
  DateRenderer,
  UserCheckingRenderer,
};

import Axios from "axios";
const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;

const searchByStocks = async ({  stockId, token }) => {
  const endPoint = "/tiktrak/searchByStocks";
  const { data } = await Axios({
    method: "post",
    baseURL: `${serverUrl}${endPoint}`,
    data: {
        stockId,
    },
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
  return data;
};

export { searchByStocks };

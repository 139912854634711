import Axios from "axios";

const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
const booking = {
  Create: async ({ booking, token }) => {
    const endPoint = "/booking/create";
    const { status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        ...booking,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return status || false;
  },
  ConfigBooking: async ({ token }) => {
    const endPoint = "/configBooking";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || {};
  },
  ConfigBookingKeyLabelConverter: async ({ category, token }) => {
    const endPoint = "/configBooking/keyLabelConverter";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || {};
  },
  DeleteById: async ({ idBooking, token }) => {
    const endPoint = "/booking/deleteById";
    const { status } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idBooking,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return status || {};
  },
  GetEventsByCategory: async ({ category, token }) => {
    const endPoint = "/booking/getEventsByCategory";
    const { data } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        category,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || {};
  },
  GetApiCompanyDiscountGrid: async ({ idCompany, token }) => {
    const endPoint = "/configBooking/companyDiscountGrid";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idCompany,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response?.data || [];
  },
  GetAllContracts: async ({ idMachine, token }) => {
    const endPoint = "/booking/contract";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idMachine,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || [];
  },
  GetById: async ({ idBooking, token }) => {
    const endPoint = "/booking/getById";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idBooking,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || {};
  },
  GetPdfBooking: async ({ idBooking, docType, token }) => {
    const endPoint = "/booking/getPdfBooking";
    const data = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        idBooking,
        docType,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data || {};
  },
  SendMeBookingByMail: async ({ idBooking, token }) => {
    const endPoint = "/booking/sendMeBookingByMail";
    const data = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        idBooking,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data || {};
  },
  Update: async ({ booking, token }) => {
    const endPoint = "/booking/update";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...booking },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  GetGridBookings: async ({ language, token }) => {
    const endPoint = "/booking/getGridBookings";
    const response = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        language: language,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return response;
  },
};

export default booking;

/*global Promise */
import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";

// icons
import SaveIcon from "@mui/icons-material/Save";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
//constants
import Styles from "../../constants/styles.json";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
import dataConfig from "../../constants/data.json";
//api
import Company from "../../api/company";
export default function ImageSize({
  companyInfo,
  userInfo,
  expanded,
  setExpanded,
  setSnackBar,
  setCompany,
  setActivityIndicator,
}) {
  const { imageSizes } = dataConfig || {};
  const {
    config: { imagesSize: imagesSizeValue },
  } = companyInfo || {};
  const [imagesSize, setImagesSize] = useState(imagesSizeValue);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setImagesSize(value);
  };
  const submitImageSize = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.updateCompanyInProgress,
    });
    const { Update } = Company || {};
    const { token } = userInfo;
    const { config } = companyInfo;
    const _company = {
      ...companyInfo,
      config: { ...config, imagesSize },
    };
    const { status } = await Update({
      company: _company,
      token,
    });
    if (status === 200) {
      _company && setCompany(_company);
      _company && localStorage.setItem("company", JSON.stringify(_company));
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanySuccess,
        status: "success",
      });
    } else {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanyError,
        status: "error",
      });
    }
    setActivityIndicator({ isOpen: false, message: null });
  };
  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "imageSize"}
      onChange={() =>
        expanded === "imageSize" ? setExpanded(null) : setExpanded("imageSize")
      }
    >
      <AccordionSummary
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PhotoSizeSelectLargeIcon style={{ color: Color.green }} />
        <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
          {Language.appBar.addImageSize}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          key="DetailsAgency"
          style={{ width: "100%" }}
          sx={{ m: 1, width: 300 }}
        >
          <InputLabel>{Language.appBar.selectImageSize}</InputLabel>
          <Select
            defaultValue={imagesSizeValue}
            value={imagesSize}
            onChange={handleChange}
            input={<OutlinedInput label={Language.appBar.selectImageSize} />}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
          >
            {imageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                <Checkbox checked={imagesSize === size} />
                <ListItemText primary={size} />
              </MenuItem>
            ))}
          </Select>

          <Button
            style={Styles.button}
            variant="contained"
            color="primary"
            disabled={imagesSize === imagesSizeValue}
            onClick={() => submitImageSize()}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.update}
          </Button>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

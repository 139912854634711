import { orange } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import Company from "./api/company";
import User from "./api/user";
import Colors from "./constants/colors.json";
import ActionModalContext from "./Contexts/ActionModalContext";
import ActivityIndicatorContext from "./Contexts/ActivityIndicatorContext";
import CompanyContext from "./Contexts/CompanyContext";
import SnackBarContext from "./Contexts/SnackBarContext";
import UserContext from "./Contexts/UserContext";
import Home from "./views/Home";
import Login from "./views/Login";

function App() {
  const globalTheme = createTheme({
    status: {
      danger: orange[500],
    },
    palette: {
      primary: {
        main: Colors.grey,
      },
      secondary: {
        main: Colors.green,
      },
    },
  });

  const [actionModal, setActionModal] = useState({
    isOpen: false,
    message: null,
    status: null,
    title: null,
  });

  const [activityIndicator, setActivityIndicator] = useState({
    isOpen: false,
    message: null,
    status: null,
  });
  const [company, setCompany] = useState();
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    message: null,
    status: null,
  });
  const [user, setUser] = useState();
  const [localProps, setLocalProps] = useState({
    isLoading: true,
  });

  const constructor = async () => {
    const _user = localStorage.getItem("user");
    const { id: idUser, idCompany, token } = JSON.parse(_user) || {};

    const { GetById: GetCompanyById } = Company || {};
    const { GetById: GetUserById } = User || {};

    const { data: _company } = _user
      ? await Promise.resolve(
          GetCompanyById({
            idCompany,
            token,
          })
        )
      : {};
    const _userFromApi = _user ? await GetUserById({ idUser, token }) : null;
    _company && setCompany(_company);
    _userFromApi
      ? setUser({ ..._userFromApi, token })
      : setUser(JSON.parse(_user));
    _userFromApi &&
      localStorage.setItem("user", JSON.stringify({ ..._userFromApi, token }));
    _company && localStorage.setItem("company", JSON.stringify(_company));
    setLocalProps({
      isLoading: false,
    });
  };
  localProps?.isLoading && constructor();

  return (
    <ThemeProvider theme={globalTheme}>
      <ActionModalContext.Provider value={{ actionModal, setActionModal }}>
        <CompanyContext.Provider value={{ company, setCompany }}>
          <ActivityIndicatorContext.Provider
            value={{ activityIndicator, setActivityIndicator }}
          >
            <SnackBarContext.Provider value={{ snackBar, setSnackBar }}>
              <UserContext.Provider value={{ user, setUser }}>
                {localProps?.isLoading ? (
                  <></>
                ) : user && company ? (
                  <Home />
                ) : (
                  <Login />
                )}
              </UserContext.Provider>
            </SnackBarContext.Provider>
          </ActivityIndicatorContext.Provider>
        </CompanyContext.Provider>
      </ActionModalContext.Provider>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import Colors from "../constants/colors.json";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const MultipleDesignationInput = ({ inputList = [], onChange }) => {
  const handleAddInput = () => {
    onChange([
      ...inputList,
      { key: `cost_${inputList?.length}`, label: "", value: "" },
    ]);
  };

  const handleDeleteInput = ({ inputKey }) => {
    const _inputList = inputList.filter(({ key }) => key !== inputKey);
    onChange(_inputList);
  };

  const handleChange = ({ inputKey, inputLabel, inputValue }) => {
    const _inputList = inputList.map(({ key, label, value }) =>
      key === inputKey
        ? { key, label: inputLabel, value: inputValue }
        : { key, label, value }
    );
    onChange(_inputList);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: 10,
      }}
    >
      {inputList.map(
        ({ key: inputKey, label: inputLabel, value: inputValue }) => (
          <div
            id={inputKey}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            <TextField
              style={{ flex: 10 }}
              size="small"
              label="Désignation"
              value={inputLabel}
              onChange={({ target }) =>
                handleChange({ inputKey, inputLabel: target.value, inputValue })
              }
            />
            <TextField
              style={{ flex: 5 }}
              size="small"
              label="Montant"
              value={inputValue}
              onChange={({ target }) =>
                handleChange({ inputKey, inputLabel, inputValue: target.value })
              }
            />
            <Tooltip title="Ajouter un supplément">
              <IconButton onClick={() => handleDeleteInput({ inputKey })}>
                <DeleteIcon sx={{ color: Colors.warning }} />
              </IconButton>
            </Tooltip>
          </div>
        )
      )}
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Tooltip title="Ajouter un supplément">
          <IconButton
            onClick={() => handleAddInput()}
            style={{ backgroundColor: Colors.green }}
          >
            <AddIcon sx={{ color: Colors.white }} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default MultipleDesignationInput;

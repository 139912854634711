import React, { useContext, useState } from "react";
import {
  Box,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";

import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import Colors from "../constants/colors.json";

const LokaActivityIndicator = () => {
  const { activityIndicator, setActivityIndicator } = useContext(
    ActivityIndicatorContext
  );
  const { isOpen, message, status } = activityIndicator || {};

  return (
    <Modal
      open={isOpen}
      onClose={() =>
        setActivityIndicator({ isOpen: false, message: null, status: null })
      }
    >
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          backgroundColor="white"
          width={400}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexDirection="column"
          padding={2}
          borderRadius={3}
        >
          <CircularProgress color="secondary" />
          <Typography color={Colors.grey}>{message}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default LokaActivityIndicator;

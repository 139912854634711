const getDiscountList = ({ contractType, discountGrid, rentalPricesKey }) => {
  const discountConverter = { dayPrice: "day", unityPrice: "unity" };
  const { grid = {} } =
    discountGrid?.find(({ key }) => key === contractType) || {};
  const { gridKey, gridValue } = grid?.[discountConverter?.[rentalPricesKey]]
    ? {
        gridKey: discountConverter?.[rentalPricesKey],
        gridValue: grid?.[discountConverter?.[rentalPricesKey]],
      }
    : grid?.percentage
    ? { gridKey: "percentage", gridValue: grid?.percentage }
    : grid?.default
    ? { gridKey: "default", gridValue: grid?.default }
    : { gridKey: null, gridValue: null };

  return gridKey ? [{ key: gridKey, value: gridValue }] : [];
};

const getDiscountPrices = ({
  dayPrice,
  delivery,
  discountKey,
  discountValue,
  rentalPrices,
  unityPrice,
  equipmentsSelected = {},
  servicesSelected = {},
}) => {
  const deliveryAmount =
    delivery?.key === "toDeliver" ? parseFloat(delivery?.totalAmount) || 0 : 0;

  const equipmentsAmount = Object.entries(equipmentsSelected)?.length
    ? Object.entries(equipmentsSelected)
        .map(([key, value]) => value?.[rentalPrices?.key] || 0)
        ?.reduce(
          (acc, curr) =>
            acc + parseFloat(curr) * parseFloat(rentalPrices?.quantity || 0),
          0
        )
    : 0;

  const servicesAmount = Object.entries(servicesSelected)?.length
    ? Object.entries(servicesSelected)
        .map(([key, value]) => value || 0)
        ?.reduce((acc, curr) => acc + parseFloat(curr), 0)
    : 0;

  const rentalAmount =
    parseFloat(rentalPrices?.quantity || 0) *
    parseFloat(rentalPrices?.[rentalPrices?.key] || 0);
  const grossAmount = (
    rentalAmount +
    equipmentsAmount +
    servicesAmount +
    deliveryAmount
  )?.toFixed(2);
  
  const _netDiscountObject = {
    day: (parseFloat(dayPrice) * parseFloat(discountValue))?.toFixed(2),
    unity: (parseFloat(unityPrice) * parseFloat(discountValue))?.toFixed(2),
    default: parseFloat(discountValue)?.toFixed(2),
    percentage: (
      (parseFloat(discountValue) / 100) *
      (parseFloat(rentalAmount) + parseFloat(equipmentsAmount))
    ).toFixed(2),
  };
  
  const _netDiscount =
    parseFloat(_netDiscountObject?.[discountKey] || 0) < parseFloat(grossAmount)
      ? _netDiscountObject?.[discountKey]
      : grossAmount;

  const netAmount = (
    parseFloat(grossAmount) - parseFloat(_netDiscount || 0)
  )?.toFixed(2);

  return {
    grossAmount,
    netDiscount: _netDiscount,
    netAmount,
    rentalAmount,
  };
};

export { getDiscountList, getDiscountPrices };

import UsersIcon from "@mui/icons-material/PeopleAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import UserApi from "../api/user";
import Colors from "../constants/colors.json";
import DefaultUserConfig from "../constants/data.json";
import Language from "../constants/language_fr.json";
import { setMembers } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import SaveIcon from "@mui/icons-material/Save";
import SnackBarContext from "../Contexts/SnackBarContext";
import LockResetIcon from "@mui/icons-material/LockReset";

export default function TeamManagement() {
  const dispatch = useDispatch();
  const {
    bookingRestrictionsConfig,
    checkingRequirementsConfig,
    emailNotificationsConfig,
    licencesConfig,
  } = DefaultUserConfig || {};

  const { setSnackBar } = useContext(SnackBarContext);
  const { company, members, user } = useSelector(({ slice }) => slice.value);

  const [rowData, setRowData] = useState(
    members?.filter(({ isActive }) => isActive)
  );
  const [uploading, setUploading] = useState(false);
  const [formUser, setFormUser] = useState(null);

  const fileInput = useRef(null);

  const hasUserChanged = formUser
    ? JSON.stringify(formUser) !==
      JSON.stringify(rowData?.find(({ id }) => formUser?.id === id))
    : false;
  const handleSaveUser = async () => {
    const message = {
      error: `La mise à jour de ${formUser?.firstName} ${formUser?.lastName} a échoué.`,
      success: `👏 ${formUser?.firstName} ${formUser?.lastName} a bien été modifié.`,
    };
    const { data, status } = await UserApi.Update({
      user: formUser,
      token: user.token,
    });

    if (status === 200) {
      setFormUser(null);
      const _rowData = rowData?.map((row) =>
        data?.response?._id === row?._id ? data?.response : row
      );
      setRowData(_rowData);
      dispatch(setMembers(_rowData));
      setSnackBar({
        isOpen: true,
        message: message.success,
        status: "success",
      });
    } else {
      setFormUser(null);
      setSnackBar({
        isOpen: true,
        message: message.error,
        status: "error",
      });
    }
  };

  const handleSetUser = async ({ item, section }) => {
    /**Parent and children sections allows to pass root section to configuration or composed section (requirements.checking for example) */
    const [parentSection, childrenSection] = section?.split(".");
    const _configuration = childrenSection
      ? {
          ...(formUser?.configuration || {}),
          [parentSection]: {
            ...(formUser?.configuration?.[parentSection] || {}),
            [childrenSection]: {
              ...(formUser?.configuration?.[parentSection]?.[childrenSection] ||
                {}),
              [item]:
                !formUser?.configuration?.[parentSection]?.[childrenSection]?.[
                  item
                ],
            },
          },
        }
      : {
          ...(formUser?.configuration || {}),
          [parentSection]: {
            ...(formUser?.configuration?.[parentSection] || {}),
            [item]: !formUser?.configuration?.[parentSection]?.[item],
          },
        };
    setFormUser((_formUser) => ({
      ..._formUser,
      configuration: _configuration,
    }));
  };

  const handleSetEmailRecipients = ({ key, value }) => {
    const _emailNotificationsRecipients = {
      ...formUser?.configuration?.emailNotificationsRecipients,
      [key]: value,
    };

    setFormUser({
      ...formUser,
      configuration: {
        ...formUser?.configuration,
        emailNotificationsRecipients: _emailNotificationsRecipients,
      },
    });
  };

  const handleSetUserGroup = (chipGroup) => {
    const _group = formUser?.machineGroup?.includes(chipGroup)
      ? formUser?.machineGroup?.filter((group) => group !== chipGroup)
      : [...(formUser?.machineGroup || []), chipGroup];
    console.log({ _group });
    setFormUser((_formUser) => ({ ..._formUser, machineGroup: _group }));
  };

  const columnDefs = [
    {
      filter: "textColumn",
      headerName: Language.headerGrid.userTitle,
      valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
    },
    {
      field: "licence",
      filter: "textColumn",
      headerName: Language.headerGrid.userLicence,
    },
    {
      field: "cellPhone",
      filter: "textColumn",
      headerName: Language.headerGrid.cellPhone,
    },
    {
      field: "email",
      filter: "textColumn",
      headerName: Language.headerGrid.email,
    },
    {
      field: "machineGroup",
      headerName: Language.headerGrid.machineGroup,
      cellRenderer: ({ data }) => data?.machineGroup?.join(" | "),
    },
  ];
  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const handleResetPassword = async () => {
    await UserApi.ResetPassword({
      user: formUser,
      token: user.token,
    });

    setSnackBar({
      isOpen: true,
      message: Language.headerGrid.successPasswordReset,
      status: "success",
    });
  };

  const handleUploadXls = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    var extension = file.name.split(".").pop().toLowerCase();
    var allowed = ["xls", "xlsx"];

    if (allowed.includes(extension)) {
      const formData = new FormData();
      formData.append("filedata", file);
      await UserApi.importFromXls({ formData, token: user.token });
      setUploading(false);
      fileInput.current.value = null;
    } else {
      setUploading(false);
      fileInput.current.value = null;
    }
  };

  const rowClassRules = {
    isUserDisabled: function (params) {
      return user?.licence !== "manager";
    },
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={formUser !== null}
        onClose={() => setFormUser(null)}
      >
        <div
          style={{
            padding: 25,
            display: "flex",
            gap: 15,
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{`${formUser?.firstName} ${formUser?.lastName}`}</Typography>
            <Button disabled={!hasUserChanged} onClick={() => handleSaveUser()}>
              <SaveIcon
                style={
                  hasUserChanged
                    ? { color: Colors.green }
                    : { color: Colors.greyShaded }
                }
              />
            </Button>
          </div>
          <Divider color={Colors.greyShaded} />
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Licence</InputLabel>
                <Select
                  disableUnderline
                  id="user-licence-select"
                  label="Licence"
                  onChange={({ target }) =>
                    setFormUser((_formUser) => ({
                      ..._formUser,
                      licence: target?.value,
                    }))
                  }
                  value={formUser?.licence}
                >
                  {licencesConfig?.map((lic) => (
                    <MenuItem value={lic}>
                      <Typography variant="body1">{lic}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <ToggleButtonGroup
                color="primary"
                value={formUser?.isActive}
                exclusive
                onChange={({ target }) =>
                  setFormUser((_formUser) => ({
                    ..._formUser,
                    isActive: target.value === "true" ? true : false,
                  }))
                }
                aria-label="Platform"
              >
                {[
                  { label: "Actif", value: true },
                  { label: "Inactif", value: false },
                ].map(({ label, value }) => {
                  const _style =
                    value && value === formUser?.isActive
                      ? {
                          backgroundColor: Colors.green,
                          color: Colors.white,
                        }
                      : value === formUser?.isActive
                      ? {
                          backgroundColor: Colors.warning,
                          color: Colors.white,
                        }
                      : {};
                  return (
                    <ToggleButton style={_style} value={value}>
                      {label}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleResetPassword}
                title="Réinitialiser mot de passe"
              >
                <LockResetIcon style={{ color: Colors.green }} />
              </Button>
            </div>
          </div>
          <div>
            <Typography
              style={{ color: Colors.grayText }}
            >{`Destinataires des notifications email`}</Typography>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 10,
              }}
            >
              {[
                "onCreateContract",
                "onUpdateContract",
                "onCreateChecking",
                "onNotesCheckings",
                "onCloseChecking",
                "onUndoChecking",
              ].map((emailNotificationElement) => (
                <FormControl fullWidth size="small">
                  <InputLabel
                    id={`emailNotificationRecipients_${emailNotificationElement}`}
                  >
                    {
                      Language?.emailNotificationsRecipients?.[
                        emailNotificationElement
                      ]
                    }
                  </InputLabel>
                  <Select
                    labelId={`emailNotificationRecipients_${emailNotificationElement}`}
                    id={emailNotificationElement}
                    multiple
                    value={
                      formUser?.configuration?.emailNotificationsRecipients?.[
                        emailNotificationElement
                      ] || []
                    }
                    label={
                      Language?.emailNotificationsRecipients?.[
                        emailNotificationElement
                      ]
                    }
                    onChange={({ target }) =>
                      handleSetEmailRecipients({
                        key: emailNotificationElement,
                        value: target?.value,
                      })
                    }
                  >
                    {members?.map(({ email, firstName, lastName }) => (
                      <MenuItem key={email} value={email}>
                        {`${firstName} ${lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </div>
            <Divider style={{ marginTop: 20 }} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            {[
              {
                title: "Groupe(s)",
                chipsItems: (company?.config?.groupList || []).map((item) => ({
                  label: item,
                  value: item,
                })),
                noItemsLabel: `Aucun groupe défini pour ${company?.name}`,
                onClick: (item) => handleSetUserGroup(item),
                inputValue: formUser?.machineGroup,
              },
              // {
              //   title: "Notifications emails",
              //   chipsItems: (emailNotificationsConfig || []).map((item) => ({
              //     label: Language.emailNotifRenderer?.[item],
              //     value: item,
              //   })),
              //   noItemsLabel: `Aucun type de notification défini pour ${company?.name}`,
              //   onClick: (item) =>
              //     handleSetUser({
              //       item,
              //       section: "emailNotifications",
              //     }),
              //   inputValue: Object.entries(
              //     formUser?.configuration?.emailNotifications || {}
              //   )
              //     ?.filter(([key, value]) => value)
              //     ?.map(([key, value]) => key),
              // },
              {
                title: "Interdictions",
                chipsItems: (bookingRestrictionsConfig || []).map((item) => ({
                  label: Language.restrictionRender?.[item],
                  value: item,
                })),
                noItemsLabel: `Aucun type de restriction défini pour ${company?.name}`,
                onClick: (item) =>
                  handleSetUser({
                    item,
                    section: "restrictions",
                  }),
                inputValue: Object.entries(
                  formUser?.configuration?.restrictions || {}
                )
                  ?.filter(([key, value]) => value)
                  ?.map(([key, value]) => key),
              },
              {
                title: "Champs requis (état des lieux)",
                chipsItems: (checkingRequirementsConfig || []).map(
                  ({ key, label }) => ({
                    label: label,
                    value: key,
                  })
                ),
                noItemsLabel: `Aucun type de champ requis défini pour ${company?.name}`,
                onClick: (item) =>
                  handleSetUser({
                    item,
                    section: "requirements.checking",
                  }),
                inputValue: Object.entries(
                  formUser?.configuration?.requirements?.checking || {}
                )
                  ?.filter(([key, value]) => value)
                  ?.map(([key, value]) => key),
              },
            ].map(
              ({ chipsItems, inputValue, noItemsLabel, onClick, title }) => (
                <div>
                  <Typography style={{ color: Colors.grayText }}>
                    {title}
                  </Typography>
                  <div
                    style={{
                      flex: 5,
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 10,
                    }}
                  >
                    {chipsItems?.length ? (
                      chipsItems?.map(({ label, value }) => {
                        const isActive = inputValue?.includes(value);
                        return (
                          <Chip
                            style={
                              isActive
                                ? {
                                    backgroundColor: Colors.green,
                                    color: Colors.white,
                                  }
                                : {}
                            }
                            label={label}
                            onClick={() => onClick(value)}
                          />
                        );
                      })
                    ) : (
                      <Typography style={{ color: Colors.greyShaded }}>
                        {noItemsLabel}
                      </Typography>
                    )}
                  </div>
                  <Divider style={{ marginTop: 20 }} />
                </div>
              )
            )}
          </div>
        </div>
      </Dialog>
      <div style={{ flex: 1 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: Colors.green, marginTop: "25px" }}
          >
            <UsersIcon style={{ color: "green", marginRight: "15px" }} />
            {company.name}
          </Typography>
          {user?.licence === "manager" ? (
            <>
              <Tooltip title={Language.headerGrid.uploadUserFile}>
                <UploadFileIcon
                  style={{
                    color: uploading ? Colors.greyShaded : Colors.green,
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                  onClick={() => fileInput?.current?.click()}
                />
              </Tooltip>
              <input
                type="file"
                onChange={handleUploadXls}
                accept=".xlsx, .xls"
                ref={fileInput}
                style={{ display: "none" }}
              />
            </>
          ) : null}
        </Box>
        <Divider />
        <div
          id="myGrid"
          role="grid"
          //className="ag-theme-alpine"
          style={{ flex: 1 }}
        >
          <AgGridReact
            autoSizeStrategy={{ type: "fitGridWidth" }}
            localeText={AG_GRID_LOCALE_FR}
            rowData={rowData}
            rowClassRules={rowClassRules}
            domLayout="autoHeight"
            onCellClicked={({ data }) => setFormUser(data)}
            rowStyle={{
              borderBottomWidth: 1,
              borderColor: Colors.greyShaded,
              textAlign: "center",
              fontSize: "12px",
              cursor: "pointer",
            }}
            suppressCellSelection={true}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={columnDefs}
          />
        </div>
      </div>
    </>
  );
}

/*global Promise ,process*/
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Language from "../constants/language_fr.json";
// import Axios from "axios";
// core
import {
  Tooltip,
  Typography,
  Divider,
  Grid,
  List,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
// icons

import FaceIcon from "@mui/icons-material/Face";
import SaveIcon from "@mui/icons-material/Save";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Color from "../constants/colors.json";
import User from "../api/user";

import Styles from "../constants/styles.json";

export default function Profile({
  setActivityIndicator,
  setSnackBar,
  setUser,
  userInfo,
}) {
  const [avatar, setAvatar] = useState({ preview: "", raw: "" });
  const [disableSaveUser, setDisableSaveUser] = useState(true);
  const {
    yupSchemaError: {
      firstNameRequired,
      lastNameRequired,
      emailRequired,
      phoneNumberRequired,
      emailValid,
    },
    appBar: { firstName, lastName, userPhoneNumber, userEmail },
  } = Language || {};
  const schema = yup.object().shape({
    firstName: yup.string().required(firstNameRequired),
    lastName: yup.string().required(lastNameRequired),
    cellPhone: yup.string().required(phoneNumberRequired),
    email: yup.string().email(emailValid).required(emailRequired),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onDeleteAvatar = () => {
    setDisableSaveUser(false);
    if (avatar) {
      setAvatar({ preview: "", raw: "" });
    }
    // if (logInfo.user.avatar) {
    //   logInfo.user.avatar = "";
    // }
    // if (modifiableUserInformations.avatar) {
    //   modifiableUserInformations.avatar = "";
    // }
  };
  // const handleUploadClick = async (event) => {
  //   setDisableSaveUser(false);
  //   const file = await pictureMinifier(event.target.files[0], 1024);
  //   setAvatar({
  //     preview: URL.createObjectURL(file),
  //     raw: file,
  //   });
  // };

  const updateProfileUser = async (user) => {
    setActivityIndicator({
      isOpen: true,
      message: Language.profile.updateProfileInProgress,
    });
    setDisableSaveUser(true);

    const { Update } = User || {};
    const { token, idCompany, configuration, id } = userInfo || {};
    const _user = { ...user, configuration, idCompany, id };
    try {
      const { status } = await Update({
        user: _user,
        token,
      });
      setActivityIndicator({
        isOpen: false,
        message: null,
      });
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.profile.updateUserSuccess,
          status: "success",
        });
        const _updatedUser = { ...userInfo, ...user };
        setUser(_updatedUser);
        localStorage.setItem("user", JSON.stringify(_updatedUser));
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.profile.updateUserError,
          status: "error",
        });
      }
    } catch (error) {
      setActivityIndicator({
        isOpen: false,
        message: null,
      });
      setSnackBar({
        isOpen: true,
        message: Language.profile.updateUserError,
        status: "error",
      });
    }
  };
  return (
    <>
      <Typography
        variant="h6"
        style={{ color: Color.green, marginTop: "25px" }}
      >
        <IconButton size="large" edge="start" color="inherit">
          <ManageAccountsIcon
            style={{ color: Color.green, fontSize: "30px" }}
          />
        </IconButton>
        {Language.appBar.userInformations}
      </Typography>
      <Divider />
      <Grid
        container
        direction="row"
        align="center"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} lg={6}>
          <List style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  color: Color.green,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <FaceIcon
                  style={{
                    width: 150,
                    height: 150,
                  }}
                />
              </div>
              {/* <div className="blockAvatar">
                {avatar.preview !== "" || userInfo.avatar ? (
                  <Tooltip title={Language.appBar.delete}>
                    <IconButton
                      style={{
                        color: Color.green,
                        float: "right",
                        marginTop: "-10px",
                      }}
                      onClick={() => onDeleteAvatar()}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
                <label htmlFor="upload-button">
                  {avatar.preview !== "" || userInfo.avatar ? (
                    <>
                      <img
                        src={
                          avatar.preview !== ""
                            ? avatar.preview
                            : userInfo.avatar
                        }
                        alt="avatar"
                        height="120"
                        className="updateAvatar"
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        color: Color.green,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FaceIcon
                        style={{
                          width: 150,
                          height: 150,
                        }}
                      />
                      <label>{Language.appBar.labelAvatar}</label>
                    </div>
                  )}
                </label>
                 <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleUploadClick}
                /> 
              </div> */}
            </div>
            {[
              {
                key: "firstName",
                title: firstName,
              },
              {
                key: "lastName",
                title: lastName,
              },
              {
                key: "cellPhone",
                title: userPhoneNumber,
              },
              {
                key: "email",
                title: userEmail,
              },
            ].map((element) => (
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    defaultValue={userInfo && userInfo[element.key]}
                    label={userInfo && element.title}
                    style={{ width: "100%", margin: "15px" }}
                    variant="outlined"
                    onChange={(e) => {
                      setDisableSaveUser(false);
                      onChange(e);
                    }}
                    value={value}
                    error={errors[element.key]?.message ? true : false}
                    helperText={errors[element.key]?.message}
                  />
                )}
                key={element.key}
                name={element.key}
                control={control}
                defaultValue={userInfo ? userInfo[element.key] : ""}
              />
            ))}
          </List>

          <Button
            disabled={disableSaveUser}
            style={Styles.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit(updateProfileUser)}
            startIcon={<SaveIcon />}
          >
            {Language.appBar.saveButton}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

import Axios from "axios";

const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
const company = {
  Create: async ({ company, token }) => {
    const endPoint = "/company/create";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...company },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  Update: async ({ company, token }) => {
    const endPoint = "/company/update";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...company },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  GetById: async ({ idCompany, token }) => {
    const endPoint = "/company/getById";
    const response = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idCompany: idCompany,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return response;
  },
};

export default company;

/*global Promise */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// core
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
// icons
import HomeIcon from "@mui/icons-material/Home";
import SaveIcon from "@mui/icons-material/Save";
//constants
import Styles from "../../constants/styles.json";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
//api
import Company from "../../api/company";
export default function AddAgency({
  expanded,
  setExpanded,
  companyInfo,
  setSnackBar,
  userInfo,
}) {
  const {
    addressRequired,
    postalCodeRequired,
    cityRequired,
    phoneNumberRequired,
  } = Language.yupSchemaError || {};
  const schema = yup.object().shape({
    address: yup.string().uppercase().required(addressRequired),
    postal: yup.string().uppercase().required(postalCodeRequired),
    city: yup.string().uppercase().required(cityRequired),
    phone: yup.string().uppercase().required(phoneNumberRequired),
  });
  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const addAgenceToCompany = async (data) => {
    const { GetById, Update } = Company || {};
    const { token } = userInfo;
    const company = await GetById({
      idCompany: companyInfo.id,
      token,
    });
    const { agencies, updatedAt, ...rest } = company?.data;
    
    const _agenciesName = agencies.map((agency) => agency.name);
    const foundCity = agencies.some((element) => element.city === data.city);
    if (!foundCity) {
      const _agency = { ...data, name: data.city };

      if (_agenciesName.indexOf(_agency.name) !== -1) {
        throw new Error("double_agency");
      }
      const _company = {
        ...rest,
        agencies: [...agencies, _agency],
      };
      const { status } = await Update({
        company: _company,
        token,
      });
      if (status === 200) {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.addAgencySuccess,
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.addAgencyError,
          status: "error",
        });
      }
    } else {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.existedCity,
        status: "error",
      });
    }
  };
  const { address, agencyPostal, agencyCity, agencyPhone } =
    Language.appBar || {};
  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "addAgency"}
      onChange={() =>
        expanded === "addAgency" ? setExpanded(null) : setExpanded("addAgency")
      }
    >
      <AccordionSummary
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <HomeIcon style={{ color: Color.green }} />
        <Typography
          //className={classes.sectionTitle}
          variant="subtitle1"
          style={{ marginLeft: "5px" }}
        >
          {Language.appBar.addAgency}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl key="DetailsAgency" style={{ width: "100%" }}>
          {[
            { title: address, key: "address" },
            { title: agencyPostal, key: "postal" },
            { title: agencyCity, key: "city" },
            { title: agencyPhone, key: "phone" },
          ].map((element) => {
            const { key, title } = element;
            return (
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    key={key}
                    sx={{ width: "100%", marginTop: "15px" }}
                    label={title}
                    onChange={onChange}
                    value={value}
                    error={errors[key]?.message ? true : false}
                    helperText={errors[key]?.message}
                  />
                )}
                key={key}
                name={key}
                control={control}
                defaultValue=""
              />
            );
          })}
          <Button
            style={Styles.button}
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={handleSubmit(addAgenceToCompany)}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.addAgency}
          </Button>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Color from "../constants/colors.json";
import { Tooltip } from "@material-ui/core";
import { Box, IconButton } from "@mui/material";
import Language from "../constants/language_fr.json";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "50vw",
    flexGrow: 1,
    paddingBottom: "20px",
  },
  rootFullScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: "150",
  },
  landscapeXS: {
    width: "100%",
    height: "70vh",
  },
  portraitXS: {
    height: "70vh",
    width: "auto",
  },
  landscapeLG: {
    height: window.innerHeight,
    width: "auto",
  },
  portraitLG: {
    height: window.innerHeight,
    width: "auto",
  },
  widget: {
    width: "30px",
    height: "30px",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: Color.green,
    opacity: "0.8",
    fontSize: 15,
    marginLeft: "5px",
    marginTop: "5px",
    cursor: "pointer",
  },
  particularityPublication: {
    color: "white",
    backgroundColor: "#000",
    opacity: "0.9",
    fontSize: 15,
    marginLeft: "50px",
    marginTop: "5px",
    cursor: "pointer",
    padding: "10px",
    borderRadius: "3px",
  },
  halfScreenStyle: {
    position: "relative",
    width: "50vw",
    height: "70vh",
  },
  fullScreenStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

function SliderMachinePictures({ pictures }) {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState({
    style: classes.root,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    scrollToTop: 0,
  });
  // eslint-disable-next-line
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [rotation, setRotation] = React.useState(0);
  const maxSteps = pictures && pictures.length;

  const displayImage = (step, index) => {
    let imageToDisplay = new Image();
    imageToDisplay.src = step;
    let landscape = true;
    const getOrientation = () => {
      landscape = imageToDisplay.width > imageToDisplay.height;
    };
    getOrientation();
    const imageClasses =
      landscape === true && fullScreen.lg === 6
        ? classes.landscapeXS
        : landscape === true && fullScreen.lg === 12
        ? classes.landscapeLG
        : landscape === false && fullScreen.lg === 12
        ? classes.portraitLG
        : classes.portraitXS;
    return (
      <div
        key={step}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Math.abs(activeStep - index) <= 2 ? (
          <TransformWrapper
            wheel={{
              step: 1,
            }}
          >
            <TransformComponent>
              <LazyLoadImage
                className={imageClasses}
                key="displayedImage"
                src={step}
                style={{
                  transform: `rotate(${rotation}deg)`,
                  objectFit: "contain",
                }}
                alt=""
                width="100%"
                height="100%"
              />
            </TransformComponent>
          </TransformWrapper>
        ) : null}
      </div>
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const swipeableViewsInformations = async () => {
    await Promise.resolve(document.getElementById("swipeableViews"));
  };
  const displayFullWidth = () => {
    setFullScreen({
      style: classes.rootFullScreen,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      scrollToTop: document.getElementById("swipeableViews").offsetTop,
    });
  };
  const displayHalfWidth = () => {
    setFullScreen({
      style: classes.root,
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
      scrollToTop: 0,
    });
  };

  return (
    <div id="imageSlider" className={fullScreen.style}>
      {pictures && pictures.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: Color.carouselGrey,
            }}
          >
            <IconButton
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              style={{ color: Color.green, fontSize: "2em" }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <p>
              {activeStep + 1} / {maxSteps}{" "}
            </p>
            <IconButton
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              style={{ color: Color.green, fontSize: "2em" }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>

          <div
            style={{
              position: "absolute",
              top:
                swipeableViewsInformations &&
                swipeableViewsInformations.offsetTop,
              zIndex: "20",
            }}
          >
            <div className={classes.widget}>
              {fullScreen.lg === 6 ? (
                <Tooltip title={Language.toolTip.fullscreen}>
                  <FullscreenIcon onClick={displayFullWidth} />
                </Tooltip>
              ) : (
                <Tooltip title={Language.toolTip.exitFullSreen}>
                  <FullscreenExitIcon onClick={displayHalfWidth} />
                </Tooltip>
              )}
            </div>
            <div className={classes.widget}>
              <Tooltip title={Language.toolTip.rotateLeft}>
                <RotateLeftIcon onClick={() => setRotation(rotation - 90)} />
              </Tooltip>
            </div>
            <div className={classes.widget}>
              <Tooltip title={Language.toolTip.rotateRight}>
                <RotateRightIcon onClick={() => setRotation(rotation + 90)} />
              </Tooltip>
            </div>
          </div>
        </>
      )}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        id="swipeableViews"
        style={
          pictures
            ? {
                border: "2px solid",
                borderColor: "#fff",
                backgroundColor: "#fff",
                minHeight: "70vh",
              }
            : null
        }
      >
        {pictures && pictures.length > 0 ? (
          pictures.map((step, index) => displayImage(step, index))
        ) : (
          <Typography
            component="p"
            style={{
              textAlign: "center",
              color: Color.grey,
              fontWeight: "800",
            }}
          >
            Aucune photo
          </Typography>
        )}
      </SwipeableViews>
    </div>
  );
}

export default SliderMachinePictures;

import React, { useContext, useEffect, useState } from "react";
import User from "../api/user";
import AppBar from "../components/AppBar";
import AppBottomNavigation from "../components/BottomNavigation";
// import InAndOut from "./InAndOut";
import UserContext from "../Contexts/UserContext";
import Bookings from "./Bookings";
import Calendar from "./Calendar";
import Catalog from "./Catalog";
import ActionModal from "../components/ActionModal";
import { Box } from "@mui/system";

const Home = () => {
  const { user, setUser } = useContext(UserContext);
  const { GetByCompany, GetAllUsers } = User || {};
  const [localProps, setLocalProps] = useState({
    isLoading: true,
    members: [],
    screen: 0,
  });
  const [screenIndex, setScreenIndex] = useState(0);

  const constructor = async () => {
    const { token } = user || {};
    let { data: _members, status } = await GetByCompany({ token });
    setLocalProps({
      ...localProps,
      members: _members?.sort((a, b) => (a?.firstName > b?.firstName ? 1 : -1)),
      isLoading: false,
    });
  };
  //localProps.isLoading && constructor();
  useEffect(() => {
    constructor();
  }, []);
  const screens = {
    0: <Calendar user={user} members={localProps?.members} />,
    1: <Catalog members={localProps?.members} />,
    2: <Bookings members={localProps?.members} />,
    // 2: <InAndOut />,
  };

  useEffect(() => constructor(), [screenIndex]);

  return (
    <>
      <Box
        style={{ position: "sticky", zIndex: 10, top: 0, right: 0, left: 0 }}
      >
        <AppBar />
      </Box>
      {screens[screenIndex]}
      <AppBottomNavigation
        screenIndex={screenIndex}
        setScreenIndex={(index) => setScreenIndex(index)}
      />
      <ActionModal />
    </>
  );
};
export default Home;

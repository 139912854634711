import FullCalendar from "@fullcalendar/react";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Booking from "../api/booking";
import ContractDetails from "../components/ContractDetails";
import LokaSnackBar from "../components/LokaSnackBar";
import Selector from "../components/Selector";
import Language from "../constants/language_fr.json";
import CompanyContext from "../Contexts/CompanyContext";
import SnackBarContext from "../Contexts/SnackBarContext";
import UserContext from "../Contexts/UserContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import Colors from "../constants/colors.json";
import { Tooltip } from "@material-ui/core";
import { FaTruck } from "react-icons/fa";
import LokaActivityIndicator from "../components/LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
const { GetApiCompanyDiscountGrid } = Booking || {};

const Calendar = (props) => {
  const { members } = props;
  const { company } = useContext(CompanyContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { user } = useContext(UserContext);

  const { GetById, GetEventsByCategory } = Booking || {};
  const [activeView, setActiveView] = useState("dayGridMonth");
  const [contractDetails, setContractDetails] = useState(null);
  const [selectorList, setSelectorList] = useState([]);

  const [localProps, setLocalProps] = useState({
    isLoading: true,
    screen: 0,
  });

  const constructor = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { events, resources } = await GetEventsByCategory({
      category: "all",
      token: user.token,
    });
    
    const _discountGrid = await GetApiCompanyDiscountGrid({
      idCompany: user.idCompany,
      token: user.token,
    });

    setLocalProps({
      ...localProps,
      discountGrid: _discountGrid,
      isLoading: false,
      events,
      resources,
    });
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };

  useEffect(() => {
    constructor();
  }, []);

  const {
    discountGrid,
    isLoading,
    events = [],
    resources = [],
  } = localProps || {};

  const handleCloseAndRefresh = async ({ snackbar }) => {
    const { isOpen, message, status } = snackbar || {};
    constructor();
    setContractDetails(null);
    setSnackBar({
      isOpen: isOpen,
      message: message,
      status: status,
    });
  };

  const handleEventClick = async ({ event }) => {
    const { publicId: idBooking } = event._def || {};
    const _contract = await GetById({ idBooking, token: user.token });
    setContractDetails(_contract);
  };

  const handleMergeBooking = ({ booking }) => {
    setContractDetails(booking);
    constructor();
  };

  const RenderEvent = (eventInfo) => {
    const { customer = "", delivery = "" } = eventInfo.event?.extendedProps;
    const { title = "", backgroundColor } = eventInfo.event;

    const _titleRender = {
      Semestre: (
        <Typography cursor="pointer" fontSize={12}>
          {customer}
        </Typography>
      ),
      Trimestre: (
        <Typography cursor="pointer" fontSize={12}>
          {customer}
        </Typography>
      ),
      dayGridMonth: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography title={customer} cursor="pointer" fontSize={12}>
            {customer}
          </Typography>
          <Typography title={customer} cursor="pointer" fontSize={12}>
            {title}
          </Typography>
        </div>
      ),
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        {delivery === "toDeliver" ? (
          <div
            style={{
              height: 24,
              width: 24,
              backgroundColor: Colors.white,
              padding: 5,
              borderRadius: 50,
            }}
          >
            <FaTruck
              title="Ce matériel est à livrer"
              style={{
                marginRight: 5,
                color: backgroundColor,
              }}
            />
          </div>
        ) : null}
        {_titleRender[activeView]}
      </div>
    );
  };

  const _resources = !selectorList?.length
    ? resources
    : resources.filter(({ group }) =>
        selectorList.some((g) => group.includes(g))
      );

  const _events = !selectorList?.length
    ? events
    : events.filter(({ extendedProps }) =>
        selectorList.some((g) => extendedProps.group.includes(g))
      );

  const { machineGroup } = user || {};
  const { groupList: defaultGroupList = [] } = company.config || {};
  const defaultList = machineGroup?.length ? machineGroup : defaultGroupList;

  return (
    <div style={{ paddingLeft: "5vw", paddingRight: "5vw", paddingTop: "5vh" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Selector
          defaultList={defaultList}
          multiple={true}
          selectorList={selectorList}
          setSelectorList={setSelectorList}
        />
        <Tooltip title={Language.calendar.refreshData}>
          <RefreshIcon
            style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
            onClick={() => constructor()}
          />
        </Tooltip>
      </Box>
      <FullCalendar
        schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
        defaultAllDay
        height="100vh"
        locale={frLocale}
        headerToolbar={{
          right:
            "resourceTimelineYear,Semestre,Trimestre,dayGridMonth prev,next",
        }}
        viewDidMount={({ view }) => setActiveView(view.type)}
        eventClick={(event) => handleEventClick(event)}
        plugins={[dayGridPlugin, resourceTimelinePlugin]}
        eventContent={RenderEvent}
        eventClassNames="fullCalendarEvent"
        views={{
          dayGridMonth: {
            titleFormat: {
              year: "numeric",
              month: "short",
            },
          },
          Semestre: {
            type: "resourceTimeline",
            duration: { month: 6 },
            slotDuration: "24:00:00",
          },
          Trimestre: {
            type: "resourceTimeline",
            duration: { month: 3 },
            slotDuration: "24:00:00",
          },
        }}
        resourceAreaHeaderContent={Language.calendar.machines}
        resourceGroupLabelContent={({ groupValue }) =>
          Language.calendar[groupValue]
        }
        resourceOrder="title"
        resources={_resources}
        resourceGroupField="category"
        resourceAreaWidth="20%"
        slotLabelFormat={[
          { month: "long", year: "numeric" },
          { day: "numeric" },
        ]}
        initialView={activeView}
        events={_events}
      />
      {contractDetails !== null && (
        <ContractDetails
          contractDetails={contractDetails}
          discountGrid={discountGrid}
          handleMergeBooking={(props) => handleMergeBooking(props)}
          members={members}
          setContractDetails={setContractDetails}
          handleCloseAndRefresh={(props) => handleCloseAndRefresh(props)}
        />
      )}
      <LokaSnackBar />
      <LokaActivityIndicator />
    </div>
  );
};
export default Calendar;

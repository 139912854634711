import DirectionsIcon from "@mui/icons-material/Directions";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  useJsApiLoader
} from "@react-google-maps/api";
import React, { useCallback, useRef, useState } from "react";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";




const DirectionMapView = (props) => {
  const { booking, defaultDestination, defaultOrigin, setBooking } =
    props || {};

  const [map, setMap] = useState(null);
  const [road, setRoad] = useState({
    destination: defaultDestination !== "undefined" ? defaultDestination : "",
    origin: defaultOrigin !== "undefined" ? defaultOrigin : "",
  });

  const destinationRef = useRef();
  const originRef = useRef();

  const { destination, origin } = road || {};
  const { distance, duration } = (map?.routes && map?.routes[0]?.legs[0]) || {};
  const { location } = booking?.jsonObject || {};
  const { lat, long: lng } = location || {};

  const deliveryOptions = [
    { key: "agencyPickUp" },
    { key: "toDeliver" },
    { key: "toDeliverRoundTrip" },
  ];

  const directionsItems = [
    { key: "distance", name: "Distance", value: distance?.text },
    { key: "duration", name: "Durée", value: duration?.text },
  ];

  const handleChangeDeliveryPrice = ({ key, value }) => {
    setBooking({
      ...booking,
      delivery: { ...booking?.delivery, [key]: value },
    });
  };

  const handleSwitchDelivery = (props) => {
    const { group, key } = props || {};
    const {
      key: bookingDeliveryKey,
      distance,
      kmPrice,
    } = booking.delivery || {};
    const distanceFactor =
      bookingDeliveryKey === "toDeliver" && key === "toDeliverRoundTrip"
        ? 2
        : bookingDeliveryKey === "toDeliverRoundTrip" && key === "toDeliver"
        ? 0.5
        : 1;
    const _distance =
      key === "agencyPickUp"
        ? "0"
        : (parseFloat(distance || "0") * distanceFactor).toFixed(2);

    const _totalAmount = (parseFloat(kmPrice) * _distance).toFixed(2);

    setBooking({
      ...booking,
      [group]: {
        ...booking?.delivery,
        key: key,
        distance: String(_distance),
        totalAmount: _totalAmount,
      },
    });
  };

  const handleValid = () => {
    const _destination = destinationRef?.current?.value || null;
    const _origin = originRef?.current?.value || null;
    setRoad({ destination: _destination, origin: _origin });
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
  });

  const directionServiceCallback = (response) => {
    const hasToUpdate = !(JSON.stringify(response) === JSON.stringify(map));
    const { status } = response;
    const { key: deliveryKey } = booking?.delivery || {};
    const { distance, duration } =
      status === "OK" ? response?.routes[0].legs[0] : {};
    const routeNumber = deliveryKey === "toDeliverRoundTrip" ? 2 : 1;

    const _distance = String(
      ((routeNumber * distance?.value) / 1000).toFixed(2)
    );
    const _duration = String(routeNumber * duration.value);
    const _totalAmount = String(
      (
        parseFloat(booking.delivery?.kmPrice) *
        parseFloat((routeNumber * distance?.value) / 1000)
      ).toFixed(2)
    );

    if (status === "OK" && hasToUpdate) {
      const _booking = {
        ...booking,
        delivery: {
          ...booking?.delivery,
          distance: _distance,
          duration: _duration,
          totalAmount: _totalAmount,
        },
      };
      setMap(response);
      setBooking(_booking);
    }
  };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-around">
      <Box flex={1} display="flex" flexDirection="column" padding={5}>
        {deliveryOptions.map((el) => (
          <FormControlLabel
            key={el.key}
            control={
              <Switch
                color="secondary"
                checked={booking?.delivery?.key === el.key}
                onChange={() =>
                  handleSwitchDelivery({
                    group: "delivery",
                    key: el.key,
                  })
                }
              />
            }
            label={Language.directionMapView[el.key]}
          ></FormControlLabel>
        ))}
        {["toDeliver", "toDeliverRoundTrip"].includes(
          booking?.delivery?.key
        ) ? (
          <Box
            borderTopWidth={1}
            borderTopStyle="solid"
            borderTopColor={Colors.greyShaded}
            height="200px"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <TextField
              key="distanceDeliveryPrice"
              name="distanceDeliveryPrice"
              label="Prix au km"
              value={booking.delivery?.kmPrice || "0"}
              onChange={({ target }) =>
                handleChangeDeliveryPrice({
                  key: "kmPrice",
                  value: target.value,
                })
              }
            />
            <TextField
              key="distance"
              name="distance"
              label="Distance"
              value={booking.delivery?.distance || "0"}
              onChange={({ target }) =>
                handleChangeDeliveryPrice({
                  key: "distance",
                  value: target.value,
                })
              }
            />
          </Box>
        ) : null}
      </Box>
      <Box flex={3}>
        {isLoaded &&
        ["toDeliver", "toDeliverRoundTrip"].includes(booking?.delivery?.key) ? (
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
              padding={2}
            >
              <TextField
                defaultValue={origin}
                inputRef={originRef}
                key="origin"
                name="origin"
                label="Départ"
              />
              <TextField
                defaultValue={destination}
                inputRef={destinationRef}
                key="destination"
                label="Destination"
                name="destination"
              />
              <Button
                onClick={handleValid}
                sx={{ backgroundColor: Colors.green }}
              >
                <DirectionsIcon sx={{ color: Colors.white }} />
              </Button>
              {distance && duration ? (
                <Box>
                  {directionsItems.map(
                    (el) =>
                      el.value && (
                        <Typography
                          key={el.name}
                          fontStyle="italic"
                          fontSize={12}
                        >{`${el.name}: ${el.value}`}</Typography>
                      )
                  )}
                </Box>
              ) : null}
            </Box>
            <Box>
              <GoogleMap
                mapContainerStyle={{ height: "400px", width: "100%" }}
                center={{
                  lat: parseFloat(lat),
                  lng: parseFloat(lng),
                }}
                zoom={10}
                onUnmount={onUnmount}
              >
                {destination && origin && (
                  <>
                    <DirectionsService
                      options={{
                        destination: destination,
                        origin: origin,
                        travelMode: "DRIVING",
                      }}
                      callback={directionServiceCallback}
                    />

                    <DirectionsRenderer
                      options={{
                        directions: map,
                      }}
                    />
                  </>
                )}
              </GoogleMap>
            </Box>
          </>
        ) : (
          <Typography textAlign="center" marginTop={10} color={Colors.grey}>
            {Language.directionMapView.noTransportModeSelected}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
export default DirectionMapView;

import React, { useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ActionModalContext from "../Contexts/ActionModalContext";
import Language from "../constants/language_fr.json";

export default function ActionModal() {
  const { actionModal, setActionModal } = useContext(ActionModalContext);

  const { handleValid, isOpen, message, status, title } = actionModal || {};

  const handleClose = () => {
    setActionModal({ message: null, status: null, isOpen: false });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => handleClose()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>
            {Language.actionModal.cancel}
          </Button>
          <Button
            onClick={() => {
              handleValid();
              handleClose();
            }}
          >
            {Language.actionModal.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import {
  AppBar,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import machineConverter from "../api/machine";
import Color from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import UserContext from "../Contexts/UserContext";
import AppCarousel from "./SliderMachinePictures";
import ActionModalContext from "../Contexts/ActionModalContext";
import SnackBarContext from "../Contexts/SnackBarContext";
import AddUpdateMachine from "./AddUpdateMachine";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Machine from "../api/machine";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function MachineDetails({
  data,
  isVisible,
  internIdList,
  rebuildMachineList,
  setMachineDetails,
}) {
  const { user } = useContext(UserContext);
  const { setActionModal } = useContext(ActionModalContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const [localProps, setLocalProps] = useState({
    labels: false,
    loading: true,
  });
  const [isOpenAddMachine, setIsOpenAddMachine] = useState(false);
  const {
    deleteMachine: deleteMachineRestriction,
    updateMachine: updateMachineRestriction,
  } = user?.configuration?.restrictions || {};

  const { jsonObject } = data || {};
  const { machineDetailsTitle } = Language;
  const { _id: _idMachine, id: idMachine, pictures } = jsonObject || {};
  const _pictures = Object.values(pictures || {}) || [];
  const keys = [
    "machine",
    "machineFeatures",
    "options",
    "rentMode",
    "benefits",
  ];
  const _dataShow = keys
    .filter((key) => jsonObject[key])
    .map((key) => {
      const values = Object.fromEntries(
        Object.entries(jsonObject[key])?.filter(([key, value]) => value)
      );

      return {
        detailsGroup: key,
        title: machineDetailsTitle[key],
        details: values,
      };
    });
  const { group } = jsonObject;

  const constructor = async () => {
    try {
      const result = await machineConverter.ConfigKeyLabelConverter({
        category: data.category,
        token: user.token,
      });
      const categoryBuilder = await machineConverter.Config({
        category: data.category,
        token: user.token,
      });
      const machine = await Machine.getById({
        idMachine: data?.id,
        token: user.token,
      });
      setLocalProps({
        categoryBuilder,
        labels: result,
        loading: false,
        machine,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const machineDetailsRender = ({ detailsGroup, details }) => {
    const { categoryBuilder, labels } = localProps || {};

    return Object.entries(details)
      ?.filter(([key, value]) => key !== "category")
      .map(([key, value]) => {
        const label = labels?.[key];

        const _value =
          typeof value === "object" && !Array.isArray(value) && value !== null
            ? Object.entries(value).map(([subKey, subValue]) => {
                return {
                  label: Language.forfaitLocation[subKey],
                  value: subValue,
                };
              })
            : value;
        const type = categoryBuilder?.[detailsGroup]?.items?.find(
          ({ key: builderKey }) => builderKey === key
        )?.type;
        return { label, type, value: _value };
      });
  };

  const handleDeleteMachine = async () => {
    const deleteMachine = async () => {
      const response = await machineConverter.DeleteById({
        idMachine,
        token: user.token,
      });
      if (response) {
        setMachineDetails({ data: false, isVisible: false });
        rebuildMachineList();
      }
      {
        rebuildMachineList();
      }
    };
    setActionModal({
      handleValid: () => deleteMachine(),
      isOpen: true,
      message: Language.actionModal.definitlyDeleted,
      status: null,
      title: Language.actionModal.caution,
    });
  };

  const appBarButtonList = [
    {
      key: "updateMachine",
      icon: <ModeEditIcon />,
      onClick: () => setIsOpenAddMachine(true),
      restriction: updateMachineRestriction,
      tooltip: machineDetailsTitle?.updateMachine,
    },
    {
      key: "deleteMachine",
      icon: <DeleteIcon />,
      onClick: () => handleDeleteMachine(),
      restriction: deleteMachineRestriction,
      tooltip: Language.toolTip.delete,
    },
  ];

  const gridLevels = (label, value) => {
    return (
      <Box key={label} sx={{ paddingLeft: "10px" }}>
        <Typography
          component="p"
          sx={{
            color: Color.grey,
            paddingRight: "15px",
            fontSize: "15px",
          }}
        >
          {label}
          {value !== true && " : "}
          <span style={{ fontWeight: "800" }}>
            {!Array.isArray(value) ? value : ""}
          </span>
        </Typography>
        {Array.isArray(value) && (
          <Table>
            <TableBody
              sx={{
                border: `solid 1px ${Color.greyHighShaded}`,
              }}
            >
              {value.map(({ key, buyIn, levelPrice }, index) => (
                <TableRow key={key}>
                  <TableCell>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        color: Color.grey,
                        fontSize: "15px",
                      }}
                    >
                      {Language.forfaitLocation.buyIn} {buyIn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="p"
                      sx={{
                        color: Color.grey,
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {levelPrice} {Language.forfaitLocation.priceHours}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    );
  };

  useEffect(() => {
    constructor();
  }, []);

  return (
    <>
      {isOpenAddMachine && (
        <AddUpdateMachine
          internIdList={internIdList}
          machineProps={localProps?.machine}
          handleClose={() => setIsOpenAddMachine(false)}
          handleMergeMachine={rebuildMachineList}
          role="updateMachine"
          setSnackBar={(props) => setSnackBar(props)}
        />
      )}
      <Modal
        open={isVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            bgcolor: "white",
          }}
        >
          <AppBar
            position="static"
            className="bar"
            sx={{ position: "relative", zIndex: "50" }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() =>
                  setMachineDetails({ data: false, isVisible: false })
                }
              >
                <Close />
              </IconButton>
              <Typography>
                {data
                  ? jsonObject.machine.model
                    ? `${jsonObject.machine.brand} - ${jsonObject.machine.model}`
                    : jsonObject.machine.brand
                  : null}
              </Typography>
              <Box sx={{ marginLeft: "auto" }}>
                {appBarButtonList.map((iconButton) => (
                  <Tooltip title={iconButton.tooltip} key={iconButton.key}>
                    <IconButton
                      disabled={iconButton?.restriction}
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={iconButton.onClick}
                    >
                      {iconButton.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          {localProps.loading && (
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translateY(-50%) translateX(-50%) ",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography
                component={"h2"}
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {Language.appBar.loading}
              </Typography>
            </Box>
          )}
          {!localProps.loading && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                height: "80vh",
              }}
            >
              <AppCarousel sx={{ marginTop: "50px" }} pictures={_pictures} />
              <Box
                sx={{
                  overflowY: "scroll",
                  height: "100%",
                  padding: "10px 25px 100px 25px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignitems: "flex-end",
                      gap: 5,
                    }}
                  >
                    <Typography textAlign="center" color={Color.greyShaded}>
                      {`${_idMachine}`}
                    </Typography>
                    <Tooltip title={Language.contractDetails.copyTooltip}>
                      <ContentCopyIcon
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={() => navigator.clipboard.writeText(_idMachine)}
                        style={{ cursor: "pointer", color: Color.green }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
                {/**Il faut récupérer le typ d'input pour afficher les données correctement */}
                {_dataShow &&
                  localProps.labels &&
                  _dataShow.map(({ detailsGroup, title, details }) =>
                    Object.keys(details)?.length ? (
                      <Fragment key={title}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            color: Color.greyShaded,
                            fontWeight: "900",
                            paddingBottom: "5px",
                            borderBottom: `solid 2px ${Color.greyHighShaded}`,
                            margin: "5px 0px",
                          }}
                        >
                          {title}
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                            gap: "5px",
                          }}
                        >
                          {machineDetailsRender({ detailsGroup, details }).map(
                            ({ label, type, value }) => (
                              <Box
                                key={label}
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  component="p"
                                  sx={{
                                    color: Color.grey,
                                    paddingRight: "15px",
                                    fontSize: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {["default", "numeric", "picker"].includes(
                                    type
                                  ) ? (
                                    `${label} : ${value}`
                                  ) : type === "calendarInput" ? (
                                    `${label} : ${new Date(
                                      value?.[0]
                                    )?.toLocaleDateString("fr-FR", {
                                      //weekday: "short",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}`
                                  ) : Array.isArray(value) ? (
                                    value.map(({ label, value }) =>
                                      gridLevels(label, value)
                                    )
                                  ) : (
                                    <>
                                      <DoneIcon sx={{ color: Color.green }} />
                                      {`${label}`}
                                    </>
                                  )}
                                </Typography>
                                {}
                              </Box>
                            )
                          )}
                        </Box>
                      </Fragment>
                    ) : null
                  )}
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: Color.greyShaded,
                    fontWeight: "900",
                    paddingBottom: "5px",
                    borderBottom: `solid 2px ${Color.greyHighShaded}`,
                    margin: "5px 0px",
                  }}
                >
                  {machineDetailsTitle.groups}
                </Typography>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {group?.length ? (
                    group.map((_group) => (
                      <Box
                        key={_group}
                        style={{
                          borderRadius: 20,
                          padding: 10,
                          margin: 5,
                          backgroundColor: Color.green,
                        }}
                      >
                        <Typography style={{ color: Color.white }}>
                          {_group}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography>{machineDetailsTitle.noGroup}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
}
export default MachineDetails;

import React, { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";

import SnackBarContext from "../Contexts/SnackBarContext";

const LokaSnackbar = () => {
  const { snackBar, setSnackBar } = useContext(SnackBarContext);
  const { isOpen, message, status } = snackBar || {};

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
    >
      <Alert severity={status} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default LokaSnackbar;

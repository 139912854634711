import Axios from "axios";

const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;

const DeleteCheckingById = async ({ bookingId, checkingKey, token }) => {
  const endPoint = "/checkings/deleteById";
  const { data, status } = await Axios({
    method: "post",
    baseURL: `${serverUrl}${endPoint}`,
    data: {
      bookingId,
      checkingKey,
    },
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
  return { data, status };
};
export { DeleteCheckingById };

/*global Promise */
import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";

// icons
import SaveIcon from "@mui/icons-material/Save";

import ListAltIcon from "@mui/icons-material/ListAlt";
//constants
import Styles from "../../constants/styles.json";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
//api
import Company from "../../api/company";
export default function AddRequiredFields({
  companyInfo,
  userInfo,
  expanded,
  setExpanded,
  setSnackBar,
  setCompany,
  setActivityIndicator,
  requiredFieldsList,
}) {
  let {
    config: { requiredFields },
  } = companyInfo || {};
  requiredFields = requiredFieldsList.filter((category) =>
    Object.keys(requiredFields).includes(category.key)
  );
  const [selectedRequiredFields, setSelectedRequiredFields] =
    useState(requiredFields);
  const [isDisabled, setIsDisabled] = useState(true);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsDisabled(false);
    setSelectedRequiredFields(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const submitRequiredFields = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.updateCompanyInProgress,
    });
    const { Update } = Company || {};
    const { token } = userInfo;
    const { config } = companyInfo;
    const _requiredFields = selectedRequiredFields.reduce(
      (acc, requiredField) => {
        const _result = {
          ...acc,
          [requiredField.key]: true,
        };
        return _result;
      },
      {}
    );
    const _company = {
      ...companyInfo,
      config: { ...config, requiredFields: _requiredFields },
    };
    const { status } = await Update({
      company: _company,
      token,
    });

    if (status === 200) {
      setIsDisabled(true);
      _company && setCompany(_company);
      _company && localStorage.setItem("company", JSON.stringify(_company));
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanySuccess,
        status: "success",
      });
    } else {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanyError,
        status: "error",
      });
    }
    setActivityIndicator({ isOpen: false, message: null });
  };

  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "requiredFields"}
      onChange={() =>
        expanded === "requiredFields"
          ? setExpanded(null)
          : setExpanded("requiredFields")
      }
    >
      <AccordionSummary
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListAltIcon style={{ color: Color.green }} />
        <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
          {Language.appBar.addRequiredFields}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          key="DetailsAgency"
          style={{ width: "100%" }}
          sx={{ m: 1, width: 300 }}
        >
          <InputLabel>{Language.appBar.selectField}</InputLabel>
          <Select
            value={selectedRequiredFields}
            onChange={handleChange}
            input={<OutlinedInput label={Language.appBar.selectCatgory} />}
            MenuProps={MenuProps}
            multiple
            renderValue={(selected) => {
              const _selectedValues = selected.map((element) => element.label);

              return selected.length <= 3
                ? _selectedValues.join(", ")
                : `${selected.length} ${Language.appBar.multipleSelectedItems}`;
            }}
          >
            {requiredFieldsList.map((element) => (
              <MenuItem key={element.key} value={element}>
                <Checkbox
                  checked={
                    selectedRequiredFields
                      .map((item) => item.key)
                      .indexOf(element.key) > -1
                  }
                />
                <ListItemText primary={element.label} />
              </MenuItem>
            ))}
          </Select>

          <Button
            style={Styles.button}
            variant="contained"
            disabled={isDisabled}
            color="primary"
            onClick={() => submitRequiredFields()}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.add}
          </Button>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Booking from "../api/booking";
import Machine from "../api/machine";
import LokaActivityIndicator from "../components/LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import "../App.css";
// !------
import AddUpdateBooking from "../components/AddUpdateBooking";
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import CheckingsHistory from "../components/CheckingsHistory";
import LokaSnackBar from "../components/LokaSnackBar";
// ! MODAL
import MachineDetails from "../components/MachineDetails";
import Selector from "../components/Selector";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import SnackBarContext from "../Contexts/SnackBarContext";
import AddIcon from "@mui/icons-material/Add";
import AddUpdateMachine from "../components/AddUpdateMachine";
import { getDiscountList } from "../globals";
import { useSelector } from "react-redux";
const { GetApiCompanyDiscountGrid, GetAllContracts } = Booking || {};

const Catalog = () => {
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { user, members, company } = useSelector(({ slice }) => slice.value);
  const {
    createBooking: createBookingRestriction,
    historyMachine: historyMachineRestriction,
    lockMachine: lockMachineRestriction,
  } = user?.configuration?.restrictions || {};

  const { setSnackBar } = useContext(SnackBarContext);

  const { GetGridCatalog } = Machine || {};

  const [initialBooking, setInitialBooking] = useState({
    bookingCosts: [],
    customer: {},
    rentalPeriod: [],
    rentalPrices: {},
    servicesSelected: {},
    equipmentsSelected: {},
    delivery: { key: "agencyPickUp" },
    state: "contract",
    machine: {},
  });
  const [isOpenAddUpBook, setIsOpenAddUpBook] = useState(false);
  const [isOpenAddMachine, setIsOpenAddMachine] = useState(false);
  const [isOpenChekingsHistory, setIsOpenChekingsHistory] = useState(false);
  const [localProps, setLocalProps] = useState({
    isGridReady: false,
    contractsByMachine: [],
    selectedMachine: {},
    uploading: false,
  });
  const [machineDetails, setMachineDetails] = useState({
    data: false,
    isVisible: false,
  });
  const [rowData, setRowData] = useState(null);
  const [selectorList, setSelectorList] = useState([]);
  const [view, setView] = useState("standard");

  const gridRef = useRef(null);
  const fileInput = useRef(null);

  const MainImage = ({ data }) => {
    const { mainPicture } = data || {};
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={50}
      >
        {mainPicture ? (
          <img height="100%" src={mainPicture} alt="machine miniature" />
        ) : (
          <Typography
            fontSize={12}
            fontStyle="italic"
            color={Colors.greyShaded}
          >
            {mainPicture}
          </Typography>
        )}
      </Box>
    );
  };

  const constructor = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { data } = await GetGridCatalog({
      token: user?.token,
      language: "fr",
    });
    const { defaultCategories } = company || {};
    const machineList = (data?.response || [])?.filter(({ category }) =>
      (defaultCategories || []).includes(category)
    );
    setRowData(machineList);
    const _discountGrid = await GetApiCompanyDiscountGrid({
      idCompany: user.idCompany,
      token: user.token,
    });

    setActivityIndicator({
      isOpen: false,
      message: null,
    });
    setMachineDetails({ data: false, isVisible: false });
    setLocalProps({
      ...localProps,
      isGridReady: true,
      discountGrid: _discountGrid,
      uploading: false,
    });
  };
  useEffect(() => {
    constructor();
  }, []);

  const {
    contractsByMachine,
    discountGrid,
    isGridReady,
    selectedMachine,
    uploading,
  } = localProps || {};

  const initBooking = ({ jsonObject }) => {
    const {
      active,
      createdAt,
      delivery,
      group,
      id,
      idCompany,
      location,
      machine,
      machineFeatures,
      options,
      rentMode,
      benefits,
      equipments,
      pictures,
      updatedAt,
      user,
    } = jsonObject || {};

    const rentalPricesKey = rentMode?.dayPrice
      ? "dayPrice"
      : rentMode?.monthPrice
      ? "monthPrice"
      : "unityPrice";

    const _discountList = getDiscountList({
      contractType: "standard",
      discountGrid,
      rentalPricesKey,
    });

    setInitialBooking({
      ...initialBooking,
      rentalPrices: {
        contractType: "standard",
        dayPrice: rentMode?.dayPrice || null,
        monthPrice: rentMode?.monthPrice || null,
        unityPrice: rentMode?.unityPrice || null,
        discountList: _discountList,
        key: rentalPricesKey,
        quantity: "0",
      },
      machine: {
        active,
        createdAt,
        delivery,
        group,
        id,
        idCompany,
        location,
        machine,
        machineFeatures,
        options,
        rentMode,
        benefits,
        equipments,
        pictures,
        updatedAt,
        user,
      },
      checker: user?.id,
      departureAgency: machine.agency,
      delivery: {
        ...initialBooking?.delivery,
        kmPrice: delivery?.distanceDeliveryPrice || "0",
      },
    });
    setIsOpenAddUpBook(true);
  };

  const handleCloseChekingsHistoryModal = () => {
    setLocalProps({ ...localProps, contractsByMachine: [] });
    setIsOpenChekingsHistory(false);
  };
  const InteractionRenderer = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { id, isBookable } = data || {};
    const { SwitchBookability } = Machine || {};

    const onHandleSwitchBookability = async () => {
      const { status } = await SwitchBookability({
        idMachine: id,
        token: user.token,
      });
      if (status === 200) {
        constructor();
        setSnackBar({
          isOpen: true,
          message: Language.catalog.onSuccessfullUpdate,
          status: "success",
        });
      }
    };

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        height={50}
      >
        <IconButton
          aria-controls={
            Boolean(anchorEl) ? "machine-shortcut-actions" : undefined
          }
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MenuIcon
            style={{ color: isBookable ? Colors.green : Colors.greyShaded }}
          />
        </IconButton>
        <Menu
          id="machine-shortcut-actions"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {[
            {
              isRestricted: createBookingRestriction,
              key: "createBooking",
              label: `Créer un contrat`,
              onClickAction: () =>
                initBooking({
                  jsonObject: data?.jsonObject,
                }),
            },
            {
              isRestricted: lockMachineRestriction,
              key: "lock",
              label: isBookable
                ? Language.catalog.lock
                : Language.catalog.unlock,
              onClickAction: () => onHandleSwitchBookability(),
            },
            {
              isRestricted: historyMachineRestriction,
              key: "machineHistory",
              label: `Historique`,
              onClickAction: () => handleMachineHistory(data),
            },
          ]?.map(({ isRestricted, key, label, onClickAction }) => (
            <MenuItem
              disabled={isRestricted || (!isBookable && key !== "lock")}
              key={key}
              onClick={() => onClickAction()}
            >
              <Typography
                style={{
                  color: Colors.grayText,
                }}
              >
                {label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const gridApiHandler = (apiType) => {
    const { api } = gridRef.current || {};
    if (api) {
      switch (apiType) {
        case "exportDataAsExcel":
          api.exportDataAsExcel();
          break;
        default:
          console.log("This grid api type is not used in INSPEKT app.");
      }
    }
  };

  const handleMachineHistory = async ({ jsonObject }) => {
    const _contracts = await GetAllContracts({
      idMachine: jsonObject?.id,
      token: user.token,
    });
    setLocalProps({
      ...localProps,
      contractsByMachine: _contracts,
      selectedMachine: jsonObject,
    });

    setIsOpenChekingsHistory(true);
  };

  const onFirstDataRendered = (params) => {
    /**Display data on full width */
    params.api.sizeColumnsToFit();
  };

  const defaultColDef = {
    resizable: true,
    rowSelection: "multiple",
    filter: "agSetColumnFilter",
    suppressMenu: true,
  };

  const columnDefs = {
    standard: [
      {
        cellRenderer: InteractionRenderer,
        filter: false,
        floatingFilter: false,
        width: 50,
      },
      {
        headerName: "Image",
        filter: false,
        floatingFilter: false,
        cellRenderer: MainImage,
        width: 100,
      },
      { field: "internId", headerName: "N° parc" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque" },
      { field: "model", headerName: "Modèle" },
      {
        headerName: "Désignation",
        type: "textColumn",
        valueGetter: ({ data }) => data?.designation || "",
      },
      {
        field: "serialNumber",
        headerName: "N° série",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        field: "agency",
        headerName: "Agence stockage",
        type: "textColumn",
      },
      {
        field: "status",
        headerName: "Statut",
        valueGetter: ({ data }) => Language.machineStatusConverter[data.status],
      },
      {
        headerName: "Contrat ouvert",
        type: "textColumn",
        valueGetter: ({ data }) => data?.openedCustomer?.name || "",
      },
    ],
    calendar: [
      {
        cellRenderer: InteractionRenderer,
        filter: false,
        floatingFilter: false,
        width: 50,
      },
      {
        headerName: "Image",
        cellRenderer: MainImage,
        filter: false,
        floatingFilter: false,
        width: 100,
      },
      { field: "internId", headerName: "N° parc" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque" },
      { field: "model", headerName: "Modèle" },
      {
        field: "serialNumber",
        headerName: "N° série",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "availableStartDate",
        headerName: "Disponible à partir du",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.availableStartDate)
            .toLocaleString("fr-FR", {
              timeZone: "Europe/Paris",
            })
            .split(" ");
          return data?.availableStartDate ? date : null;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "availableEndDate",
        headerName: "Disponible jusqu'au",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.availableEndDate)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return data?.availableEndDate ? date : null;
        },
      },
      {
        cellRenderer: ({ data: { rentalRatioInDays } }) => {
          return rentalRatioInDays
            ? `${Number((rentalRatioInDays * 100).toFixed(2))}%`
            : null;
        },
        headerName: "Tx activité",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { rentalRatioInDays } }) => {
          return rentalRatioInDays
            ? Number((rentalRatioInDays * 100).toFixed(2))
            : 0;
        },
      },
      {
        headerName: "Jours loués",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { allRentalDays } }) => {
          return allRentalDays ? Number(allRentalDays) : 0;
        },
      },
    ],
    counter: [
      {
        cellRenderer: InteractionRenderer,
        filter: false,
        floatingFilter: false,
        width: 50,
      },
      {
        headerName: "Image",
        cellRenderer: MainImage,
        filter: false,
        floatingFilter: false,
        width: 100,
      },
      {
        field: "internId",
        headerName: "N° interne",
      },
      {
        field: "serialNumber",
        headerName: "N° série",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque" },
      { field: "model", headerName: "Modèle" },
      {
        filter: "agNumberColumnFilter",
        headerName: "Année",
        valueGetter: ({ data: { year } }) => {
          const _year = Number(year);
          return isNaN(_year) ? null : _year;
        },
      },
      {
        headerName: "Compteur",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { lastCounter } }) => {
          return lastCounter ? Number(lastCounter.replace(",", ".")) : "";
        },
      },
      { field: "lastFuelLevel", headerName: "Carburant" },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        headerName: "Prochaine VGP",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.periodicGeneralInspection?.[0])
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return data?.periodicGeneralInspection?.[0] ? date : null;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        headerName: "Fin de garantie",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => data?.warrantyExpiresOn?.[0],
      },
    ],
    export: [
      {
        headerName: "Image",
        cellRenderer: MainImage,
        filter: false,
        floatingFilter: false,
        width: 100,
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque" },
      { field: "model", headerName: "Modèle" },
      { field: "internId", headerName: "N° parc" },
      {
        field: "serialNumber",
        headerName: "N° série",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        filter: "agNumberColumnFilter",
        headerName: "Année",
        valueGetter: ({ data: { year } }) => {
          const _year = Number(year);
          return isNaN(_year) ? null : _year;
        },
      },
      {
        field: "status",
        headerName: "Statut",
        valueGetter: ({ data }) => Language.machineStatusConverter[data.status],
      },
      {
        headerName: "Contrat ouvert",
        type: "textColumn",
        valueGetter: ({ data }) => data?.openedCustomer?.name || "",
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "availableStartDate",
        headerName: "Disponible à partir du",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.availableStartDate)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return data?.availableStartDate ? date : null;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "availableEndDate",
        headerName: "Disponible jusqu'au",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.availableEndDate)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return data?.availableEndDate ? date : null;
        },
      },
      {
        field: "agency",
        headerName: "Agence stockage",
        type: "textColumn",
      },
      {
        field: "lastDepartureAgency",
        headerName: "Agence dernier départ",
        type: "textColumn",
      },
      {
        field: "dayPrice",
        headerName: "Prix journée",
        type: "textColumn",
      },
      {
        field: "unityPrice",
        headerName: "Prix unitaire",
        type: "textColumn",
      },
      {
        headerName: "Compteur",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { lastCounter } }) => {
          return lastCounter ? Number(lastCounter.replace(",", ".")) : "";
        },
      },
      { field: "lastFuelLevel", headerName: "Carburant" },
      {
        cellRenderer: ({ data: { rentalRatioInDays } }) => {
          return rentalRatioInDays
            ? `${Number((rentalRatioInDays * 100).toFixed(2))}%`
            : null;
        },
        headerName: "Tx activité",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { rentalRatioInDays } }) => {
          return rentalRatioInDays
            ? Number((rentalRatioInDays * 100).toFixed(2))
            : 0;
        },
      },
      {
        headerName: "Jours loués",
        filter: "agNumberColumnFilter",
        valueGetter: ({ data: { allRentalDays } }) => {
          return allRentalDays ? Number(allRentalDays) : 0;
        },
      },
      {
        field: "group",
        headerName: "Groupes",
        type: "textColumn",
      },
    ],
    import: [
      { field: "internId", headerName: "internId", type: "textColumn" },
      {
        headerName: "category",
        type: "textColumn",
        valueGetter: ({ data }) => data.category,
      },
      { field: "brand", headerName: "brand", type: "textColumn" },
      { field: "model", headerName: "model", type: "textColumn" },
      { field: "year", headerName: "year", type: "textColumn" },
      {
        field: "serialNumber",
        headerName: "serialNumber",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        headerName: "valuePrice",
        type: "textColumn",
        valueGetter: ({ data }) => data?.valuePrice,
      },
      {
        headerName: "bondPrice",
        type: "textColumn",
        valueGetter: ({ data }) => data?.bondPrice,
      },
      {
        field: "agency",
        headerName: "agency",
        type: "textColumn",
      },
      {
        headerName: "designation",
        type: "textColumn",
        valueGetter: ({ data }) => data?.designation,
      },
      {
        field: "dayPrice",
        headerName: "dayPrice",
        type: "textColumn",
      },
      {
        field: "unityPrice",
        headerName: "unityPrice",
        type: "textColumn",
      },
      {
        headerName: "picture",
        type: "textColumn",
        valueGetter: ({ data }) => data?.mainPicture,
      },
      {
        headerName: "group",
        type: "textColumn",
        valueGetter: ({ data }) => data?.group,
      },
      {
        headerName: "distanceDeliveryPrice",
        type: "textColumn",
        valueGetter: ({ data }) => data?.distanceDeliveryPrice,
      },
      {
        valueGetter: ({ data }) => data?.availableStartDate?.split("T")?.[0],
        headerName: "availableStartDate",
        type: "textColumn",
      },
      {
        valueGetter: ({ data }) => data?.availableEndDate?.split("T")?.[0],
        headerName: "availableEndDate",
        type: "textColumn",
      },
      {
        headerName: "vgpDate",
        type: "textColumn",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.periodicGeneralInspection?.[0])
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return data?.periodicGeneralInspection?.[0] ? date : null;
        },
      },
      {
        headerName: "guidanceAntennaModel",
        type: "textColumn",
        valueGetter: ({ data }) => data?.guidanceAntenna?.model || "",
      },
      {
        headerName: "guidanceAntennaSerial",
        type: "textColumn",
        valueGetter: ({ data }) => data?.guidanceAntenna?.serialNumber || "",
      },
      {
        headerName: "guidanceScreenModel",
        type: "textColumn",
        valueGetter: ({ data }) => data?.guidanceScreen?.model || "",
      },
      {
        headerName: "guidanceScreenSerial",
        type: "textColumn",
        valueGetter: ({ data }) => data?.guidanceScreen?.serialNumber || "",
      },
      {
        headerName: "warrantyExpiresOn",
        type: "textColumn",
        valueGetter: ({ data }) => data?.warrantyExpiresOn?.[0],
      },
    ],
    prices: [
      {
        cellRenderer: InteractionRenderer,
        filter: false,
        floatingFilter: false,
        width: 50,
      },
      {
        headerName: "Image",
        cellRenderer: MainImage,
        filter: false,
        floatingFilter: false,
        width: 100,
      },
      { field: "internId", headerName: "N° parc" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque" },
      { field: "model", headerName: "Modèle" },
      {
        headerName: "Désignation",
        type: "textColumn",
        valueGetter: ({ data }) => data?.designation || "",
      },
      {
        field: "serialNumber",
        headerName: "N° série",
        valueGetter: ({ data }) => data.serialNumber || "",
      },
      {
        field: "dayPrice",
        headerName: "Prix journée",
        type: "textColumn",
      },
      {
        field: "unityPrice",
        headerName: "Prix unitaire",
        type: "textColumn",
      },
      {
        field: "status",
        headerName: "Statut",
        valueGetter: ({ data }) => Language.machineStatusConverter[data.status],
      },
    ],
  };

  const rowClassRules = useMemo(
    () => ({
      isLateDepartureOrReturn: function ({ data }) {
        return ["isLateDeparture", "isLateReturn"].includes(data.status);
      },
      isLocked: function ({ data }) {
        return data.status === "locked";
      },
    }),
    []
  );

  const { machineGroup } = user || {};
  const { groupList: defaultGroupList = [] } = company.config || {};
  const defaultList = machineGroup?.length ? machineGroup : defaultGroupList;
  const defaultViews = [
    "standard",
    "counter",
    "calendar",
    "prices",
    "export",
    "import",
  ];

  const _rowData = selectorList?.length
    ? rowData.filter(({ group }) => selectorList.some((g) => group.includes(g)))
    : rowData;

  const internIdList = rowData
    ?.map(({ internId }) => internId)
    ?.filter(
      (internId) => internId && internId !== machineDetails.data?.internId
    );

  const handleUploadXls = async (e) => {
    setLocalProps({ ...localProps, uploading: true });
    const file = e.target.files[0];
    var extension = file.name.split(".").pop().toLowerCase();
    var allowed = ["xls", "xlsx"];

    if (allowed.includes(extension)) {
      const formData = new FormData();
      formData.append("filedata", file);

      await Machine.importFromXls({ formData, token: user.token });
      constructor();
      fileInput.current.value = null;
    } else {
      setLocalProps({ ...localProps, uploading: false });
      fileInput.current.value = null;
    }
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "100%", margin: "1%", paddingBottom: "5%" }}
    >
      {isOpenAddUpBook && (
        <AddUpdateBooking
          discountGrid={discountGrid}
          handleClose={() => setIsOpenAddUpBook(false)}
          initialBooking={initialBooking}
          role="addBooking"
          setSnackBar={(props) => setSnackBar(props)}
        />
      )}
      {isOpenAddMachine && (
        <AddUpdateMachine
          machineProps={null}
          handleClose={() => setIsOpenAddMachine(false)}
          handleMergeMachine={() => constructor()}
          role="addMachine"
          setSnackBar={(props) => setSnackBar(props)}
          internIdList={internIdList}
        />
      )}
      {isOpenChekingsHistory && (
        <CheckingsHistory
          open={isOpenChekingsHistory}
          handleClose={() => handleCloseChekingsHistoryModal()}
          contractsByMachine={contractsByMachine}
          selectedMachine={selectedMachine}
          user={user}
          members={members}
        />
      )}
      <Box
        key="viewTitle"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2%",
          marginBottom: "2%",
          borderBottomColor: Colors.greyHighShaded,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="view-select-label">Vues</InputLabel>
            <Select
              labelId="view-select-label"
              id="view-select"
              value={view}
              label="Vue"
              onChange={({ target }) => setView(target.value)}
            >
              {defaultViews?.map((view) => (
                <MenuItem key={view} value={view}>
                  {Language.viewLabels[view]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Selector
            defaultList={defaultList}
            multiple={true}
            selectorList={selectorList}
            setSelectorList={setSelectorList}
          />
        </Box>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title={Language.catalog.createMachine}>
            <AddIcon
              style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
              onClick={() => setIsOpenAddMachine(true)}
            />
          </Tooltip>
          <Tooltip title={Language.catalog.refreshData}>
            <RefreshIcon
              style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
              onClick={() => constructor()}
            />
          </Tooltip>
          <Tooltip title={Language.catalog.downloadData}>
            <DownloadIcon
              style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
              onClick={() => gridApiHandler("exportDataAsExcel")}
            />
          </Tooltip>
          {user.licence === "manager" ? (
            <>
              <Tooltip title={Language.catalog.uploadMachineFile}>
                <UploadFileIcon
                  style={{
                    color: uploading ? Colors.greyShaded : Colors.green,
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                  onClick={() => fileInput.current.click()}
                />
              </Tooltip>
              <input
                type="file"
                onChange={handleUploadXls}
                accept=".xlsx, .xls"
                ref={fileInput}
                style={{ display: "none" }}
              />
            </>
          ) : null}
        </Box>
      </Box>
      {_rowData?.length && isGridReady && (
        <div className="ag-theme-grid">
          <AgGridReact
            autoSizeStrategy={{ type: "fitGridWidth" }}
            localeText={AG_GRID_LOCALE_FR}
            rowData={_rowData}
            domLayout="autoHeight"
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowStyle={{
              borderBottomWidth: 1,
              borderColor: Colors.greyShaded,
              textAlign: "center",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onCellClicked={({ colDef, data }) => {
              const { cellRenderer } = colDef || {};
              !cellRenderer && setMachineDetails({ data, isVisible: true });
            }}
            rowHeight={50}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={columnDefs?.[view]}
            paginationPageSize={100}
            pagination={true}
            rowClassRules={rowClassRules}
          />
        </div>
      )}
      {machineDetails.data && (
        <MachineDetails
          data={machineDetails.data}
          isVisible={machineDetails.isVisible}
          members={members}
          rebuildMachineList={() => constructor()}
          setMachineDetails={setMachineDetails}
          internIdList={internIdList}
        />
      )}
      <LokaSnackBar />
      <LokaActivityIndicator />
    </div>
  );
};

export default Catalog;

import React, { useContext, useState } from "react";
import JwtDecode from "jwt-decode";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MailIcon from "@mui/icons-material/Mail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Company from "../api/company";
import CompanyContext from "../Contexts/CompanyContext";
import Language from "../constants/language_fr.json";
import Styles from "../constants/styles.json";
import UserContext from "../Contexts/UserContext";
import User from "../api/user";
import LokaSnackBar from "../components/LokaSnackBar";
import SnackBarContext from "../Contexts/SnackBarContext";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState();
  const { setUser } = useContext(UserContext);
  const { setCompany } = useContext(CompanyContext);
  const { Login: UserLogin } = User || {};
  const {
    yupSchemaError: { emailRequired, emailValid, passwordRequired },
  } = Language || {};
  const schema = yup.object().shape({
    email: yup.string().email(emailValid).required(emailRequired),
    password: yup.string().required(passwordRequired),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { setSnackBar } = useContext(SnackBarContext);
  const requestLogin = async (params) => {
    const { email, password } = params || {};
    try {
      setIsLoading(true);
      const { data, status } = (await UserLogin({ email, password })) || {};
      const _decode = data?.token ? JwtDecode(data.token) : null;
      const _user = { ..._decode?.user, token: data?.token };

      const { GetById } = Company || {};
      const { data: _company } = await Promise.resolve(
        GetById({
          idCompany: _user?.idCompany,
          token: _user?.token,
        })
      );

      _user && setUser(_user);
      _user && localStorage.setItem("user", JSON.stringify(_user));
      _company && setCompany(_company);
      _company && localStorage.setItem("company", JSON.stringify(_company));
      status && setIsLoading(false);
    } catch (err) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.invalidUser,
        status: "error",
      });
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "10vh",
        }}
      >
        <CardMedia
          component="img"
          image="https://loka-prod.s3.eu-west-3.amazonaws.com/CONFIGURATION/logo_600_1500.jpg"
          alt="logo LOKA"
          sx={{ width: 400, borderRadius: 2 }}
        />
        {isLoading ? (
          <CircularProgress sx={Styles.strongMarginTop} />
        ) : (
          <Container
            sx={{ display: "flex", flexDirection: "column", width: 400 }}
          >
            <Typography
              variant="h6"
              textAlign="center"
              style={Styles.strongMarginTop}
            >
              {Language.login.logIn}
            </Typography>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  key={"email"}
                  id="email"
                  label={Language.login.emailLabel}
                  variant="outlined"
                  sx={Styles?.lightMarginTop}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={errors?.email?.message ? true : false}
                  helperText={errors?.email?.message}
                />
              )}
              control={control}
              name={"email"}
              key={"email"}
            />
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  key="password"
                  id="password"
                  label={Language.login.passwordLabel}
                  variant="outlined"
                  sx={Styles.lightMarginTop}
                  type={isPasswordVisible ? null : "password"}
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isPasswordVisible ? (
                          <VisibilityIcon
                            onClick={togglePasswordVisibility}
                            sx={{ cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={togglePasswordVisibility}
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              key={"password"}
              name={"password"}
              control={control}
            />

            <Button
              color="success"
              onClick={handleSubmit(requestLogin)}
              variant="contained"
              label="LOGIN"
              sx={Styles.lightMarginTop}
            >
              {Language.login.loginButton}
            </Button>
          </Container>
        )}
      </Card>
      <LokaSnackBar />
    </Container>
  );
};

export default Login;

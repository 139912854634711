import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import machineConverter from "../api/machine";
import { create, deleteById, getByMachineId } from "../api/maintenance";
import ContractDetails from "../components/ContractDetails";
import Color from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import { useSelector } from "react-redux";
import AppCarousel from "./SliderMachinePictures";
import ActionModalContext from "../Contexts/ActionModalContext";
import SnackBarContext from "../Contexts/SnackBarContext";
import AddUpdateMachine from "./AddUpdateMachine";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Machine from "../api/machine";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Booking from "../api/booking";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TextField from "@mui/material/TextField";
import { getDiscountList } from "../globals";
import AddUpdateBooking from "../components/AddUpdateBooking";
import "../App.css";

function MachineDetails({
  data,
  isVisible,
  internIdList,
  rebuildMachineList,
  setMachineDetails,
}) {
  const gridRef = useRef();
  const { company, members, user } = useSelector(({ slice }) => slice.value);
  const { setActionModal } = useContext(ActionModalContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const [localProps, setLocalProps] = useState({
    labels: false,
    loading: true,
  });

  const [contractDetails, setContractDetails] = useState(null);
  const [initialBooking, setInitialBooking] = useState({
    bookingCosts: [],
    customer: {},
    rentalPeriod: [],
    rentalPrices: {},
    servicesSelected: {},
    equipmentsSelected: {},
    delivery: { key: "agencyPickUp" },
    machine: {},
  });
  const [isOpenAddUpBook, setIsOpenAddUpBook] = useState(false);
  const [isOpenAddMachine, setIsOpenAddMachine] = useState(false);
  const [tabBarIndex, setTabBarIndex] = useState(0);
  const {
    deleteMachine: deleteMachineRestriction,
    updateMachine: updateMachineRestriction,
  } = user?.configuration?.restrictions || {};

  const { jsonObject } = data || {};
  const isMaintenanceDisabled = data?.categoryObject?.[0]?.unity !== "heure";
  const { machineDetailsTitle } = Language;
  const { _id: _idMachine, id: idMachine, pictures } = jsonObject || {};
  const _pictures = Object.values(pictures || {}) || [];
  const keys = [
    "machine",
    "machineFeatures",
    "options",
    "rentMode",
    "benefits",
  ];
  const _dataShow = keys
    .filter((key) => jsonObject[key])
    .map((key) => {
      const values = Object.fromEntries(
        Object.entries(jsonObject[key])?.filter(([key, value]) => value)
      );

      return {
        detailsGroup: key,
        title: machineDetailsTitle[key],
        details: values,
      };
    });
  const { group } = jsonObject;

  const constructor = async () => {
    try {
      const { GetApiCompanyDiscountGrid, GetGridEstimates, GetGridBookings } =
        Booking;
      const result = await machineConverter.ConfigKeyLabelConverter({
        category: data.category,
        token: user.token,
      });
      const companyDiscountGrid = await GetApiCompanyDiscountGrid({
        idCompany: user.idCompany,
        token: user.token,
      });

      const {
        data: { response: bookingsList = [] },
      } = await GetGridBookings({
        machineId: idMachine,
        token: user?.token,
      });
      const {
        data: { response: estimatesList = [] },
      } = await GetGridEstimates({
        machineId: idMachine,
        token: user?.token,
      });

      const categoryBuilder = await machineConverter.Config({
        category: data.category,
        token: user.token,
      });
      const machine = await Machine.getById({
        idMachine,
        token: user.token,
      });

      const {
        data: { response: maintenanceList },
      } = await getByMachineId({
        machineId: idMachine,
        token: user.token,
      });

      setLocalProps({
        bookingsList,
        categoryBuilder,
        companyDiscountGrid,
        estimatesList,
        labels: result,
        loading: false,
        machine,
        maintenanceList,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseAndRefresh = async ({ snackbar }) => {
    const { isOpen, message, status } = snackbar || {};
    constructor();
    setContractDetails(null);
    setSnackBar({
      isOpen: isOpen,
      message: message,
      status: status,
    });
  };

  const handleMergeBooking = ({ booking }) => {
    setContractDetails(booking);
    constructor();
  };

  const getMember = (memberId) => {
    const _member = members?.find(({ id }) => id === memberId[0]);
    return _member ? `${_member?.firstName} ${_member?.lastName}` : ``;
  };

  const initBooking = ({ state }) => {
    const {
      active,
      createdAt,
      delivery,
      group,
      id,
      idCompany,
      location,
      machine,
      machineFeatures,
      options,
      rentMode,
      benefits,
      equipments,
      pictures,
      updatedAt,
      user,
    } = jsonObject || {};

    const rentalPricesKey = rentMode?.dayPrice
      ? "dayPrice"
      : rentMode?.monthPrice
      ? "monthPrice"
      : "unityPrice";

    const _discountList = getDiscountList({
      contractType: "standard",
      discountGrid: localProps.companyDiscountGrid,
      rentalPricesKey,
    });

    setInitialBooking({
      ...initialBooking,
      state,
      rentalPrices: {
        contractType: "standard",
        dayPrice: rentMode?.dayPrice || null,
        monthPrice: rentMode?.monthPrice || null,
        unityPrice: rentMode?.unityPrice || null,
        discountList: _discountList,
        key: rentalPricesKey,
        quantity: "0",
      },
      machine: {
        active,
        createdAt,
        delivery,
        group,
        id,
        idCompany,
        location,
        machine,
        machineFeatures,
        options,
        rentMode,
        benefits,
        equipments,
        pictures,
        updatedAt,
        user,
      },
      checker: user?.id,
      departureAgency: machine.agency,
      delivery: {
        ...initialBooking?.delivery,
        kmPrice: delivery?.distanceDeliveryPrice || "0",
      },
      subCompany: company?.subCompanies?.[0],
    });
    setIsOpenAddUpBook(true);
  };

  const machineDetailsRender = ({ detailsGroup, details }) => {
    const { categoryBuilder, labels } = localProps || {};

    return Object.entries(details)
      ?.filter(([key, value]) => key !== "category")
      .map(([key, value]) => {
        const label = labels?.[key];

        const _value =
          typeof value === "object" && !Array.isArray(value) && value !== null
            ? Object.entries(value).map(([subKey, subValue]) => {
                return {
                  label: Language.forfaitLocation[subKey],
                  value: subValue,
                };
              })
            : value;
        const type = categoryBuilder?.[detailsGroup]?.items?.find(
          ({ key: builderKey }) => builderKey === key
        )?.type;
        return { label, type, value: _value };
      })
      ?.filter(({ label, type, value }) => label && type && value);
  };

  const handleDeleteMachine = async () => {
    const deleteMachine = async () => {
      const response = await machineConverter.DeleteById({
        idMachine,
        token: user.token,
      });
      if (response) {
        setMachineDetails({ data: false, isVisible: false });
        rebuildMachineList();
      }
      {
        rebuildMachineList();
      }
    };
    setActionModal({
      handleValid: () => deleteMachine(),
      isOpen: true,
      message: Language.actionModal.definitlyDeleted,
      status: null,
      title: Language.actionModal.caution,
    });
  };

  const appBarButtonList = [
    {
      key: "updateMachine",
      icon: <ModeEditIcon />,
      onClick: () => setIsOpenAddMachine(true),
      restriction: updateMachineRestriction,
      tooltip: machineDetailsTitle?.updateMachine,
    },
    {
      key: "deleteMachine",
      icon: <DeleteIcon />,
      onClick: () => handleDeleteMachine(),
      restriction: deleteMachineRestriction,
      tooltip: Language.toolTip.delete,
    },
  ];

  const MaintenancePlan = () => {
    const [openMaintenanceForm, setOpenMaintenanceForm] = useState(false);
    const [maintenanceForm, setMaintenanceForm] = useState({
      machineId: idMachine,
      status: "waiting",
    });

    const handleCreateMaintenance = async (event) => {
      event.preventDefault();

      const { data, status } = await create({
        body: maintenanceForm,
        token: user.token,
      });
      if (status === 200) {
        const _maintenanceList = [
          ...(localProps?.maintenanceList || []),
          data.response,
        ];
        setLocalProps((_localProps) => ({
          ..._localProps,
          maintenanceList: _maintenanceList,
        }));
        setMaintenanceForm({
          machineId: idMachine,
          status: "waiting",
        });
        setOpenMaintenanceForm(false);
      }
    };

    const handleDeleteMaintenance = async ({ maintenanceId }) => {
      const { status } = await deleteById({
        body: { maintenanceId },
        token: user.token,
      });
      if (status === 200) {
        const _maintenanceList = localProps?.maintenanceList?.filter(
          ({ _id }) => _id !== maintenanceId
        );
        setLocalProps((_localProps) => ({
          ..._localProps,
          maintenanceList: _maintenanceList,
        }));
      }
    };

    const counterUnity = `${data?.categoryObject?.[0]?.unity}(s)`;
    const { lastCounter } = data;

    return (
      <>
        <div style={{ display: "flex" }}>
          <Dialog
            onClose={() => setOpenMaintenanceForm(false)}
            open={openMaintenanceForm}
            fullWidth
            maxWidth="sm"
          >
            <form
              onSubmit={handleCreateMaintenance}
              style={{
                padding: "1vw",
                display: "flex",
                flexDirection: "column",
                gap: "3vh",
              }}
            >
              {[
                {
                  fullWidth: true,
                  helperText: "Renseigner un nombre d'heures",
                  key: "counterInterval",
                  label: "Intervalle d'entretien",
                  multiline: false,
                  required: true,
                  rows: 1,
                  type: "number",
                  variant: "filled",
                },
                {
                  fullWidth: true,
                  helperText:
                    "Renseigner le compteur au dernier entretien réalisé",
                  key: "startCounter",
                  label: "Compteur d'origine",
                  multiline: false,
                  required: true,
                  rows: 1,
                  type: "number",
                  variant: "filled",
                },
                {
                  fullWidth: true,
                  helperText: "Indiquer les détails de l'entretien",
                  key: "designation",
                  label: "Désignation",
                  multiline: true,
                  required: true,
                  rows: 4,
                  type: "text",
                  variant: "filled",
                },
              ].map(({ key, type, ...props }) => (
                <TextField
                  key={key}
                  type={type}
                  {...props}
                  onChange={({ target }) =>
                    setMaintenanceForm((_maintenanceForm) => ({
                      ..._maintenanceForm,
                      [key]:
                        type === "number" ? Number(target.value) : target.value,
                    }))
                  }
                />
              ))}
              {
                <Select
                  id="workshopManagerToAlert"
                  label="Responsable maintenance"
                  onChange={({ target }) =>
                    setMaintenanceForm((_maintenanceForm) => ({
                      ..._maintenanceForm,
                      workshopManagerToAlert: [target.value],
                    }))
                  }
                  required
                  value={maintenanceForm?.workshopManagerToAlert || null}
                >
                  {members?.map(({ id, firstName, lastName }) => (
                    <MenuItem value={id}>{`${firstName} ${lastName}`}</MenuItem>
                  ))}
                </Select>
              }
              <Button
                style={{ backgroundColor: Color.green }}
                type="submit"
                variant="contained"
              >
                Valider
              </Button>
            </form>
          </Dialog>
          <div style={{ padding: "2vw", flex: 1 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ color: Color.grey }}>
                Entretiens prévus
              </Typography>
              <Typography style={{ color: Color.grey, fontStyle: "italic" }}>
                {lastCounter &&
                  `Compteur machine : ${lastCounter} ${counterUnity}`}
              </Typography>
            </div>
            <Divider
              style={{ color: Color.greyShaded, marginBottom: "1vh" }}
              variant="middle"
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2vw" }}
            >
              {localProps?.maintenanceList?.length ? (
                localProps?.maintenanceList?.map(
                  ({
                    _id,
                    counterDeadline,
                    counterInterval,
                    designation,
                    startCounter,
                    status,
                    workshopManagerToAlert,
                  }) => {
                    const { firstName, lastName } = members?.find(
                      ({ id }) => id === workshopManagerToAlert?.[0]
                    );

                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "1vh",
                          borderRadius: "1vh",
                          gap: 10,
                          backgroundColor: Color.greyVeryHighShaded,
                        }}
                      >
                        <div
                          style={{
                            flex: 5,
                          }}
                        >
                          <Typography
                            style={{
                              color: Color.grey,
                              fontWeight: "bold",
                            }}
                          >{`Désignation`}</Typography>
                          <Typography
                            style={{ color: Color.grey }}
                          >{`${designation}`}</Typography>
                          <Divider
                            style={{
                              color: Color.greyShaded,
                              marginBottom: "2vh",
                              marginTop: "2vh",
                            }}
                            variant="middle"
                          />
                          <div style={{ display: "flex", gap: 10 }}>
                            {[
                              {
                                label: "Prochain entretien",
                                value: `${counterDeadline} ${counterUnity}`,
                              },
                              {
                                label: "Intervalle d'entretien",
                                value: `${counterInterval} ${counterUnity}`,
                              },
                              {
                                label: "Compteur d'origine",
                                value: `${startCounter} ${counterUnity}`,
                              },
                            ]?.map(({ label, value }) => (
                              <div style={{ flex: 1 }}>
                                <Typography
                                  style={{
                                    color: Color.grey,
                                    fontWeight: "bold",
                                  }}
                                >{`${label}`}</Typography>
                                <Typography
                                  style={{ color: Color.grey }}
                                >{`${value}`}</Typography>
                              </div>
                            ))}
                          </div>
                          <Divider
                            style={{
                              color: Color.greyShaded,
                              marginBottom: "2vh",
                              marginTop: "2vh",
                            }}
                            variant="middle"
                          />
                          <Typography
                            style={{
                              color: Color.grey,
                              fontWeight: "bold",
                            }}
                          >{`Responsable entretien`}</Typography>
                          <Typography
                            style={{ color: Color.grey }}
                          >{`${firstName} ${lastName}`}</Typography>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            alignSelf: "flex-start",
                            justifySelf: "center",
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleDeleteMaintenance({ maintenanceId: _id })
                            }
                          >
                            <DeleteIcon style={{ color: Color.warning }} />
                          </IconButton>
                          {/* <Typography>{`${status}`}</Typography> */}
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <Typography
                  style={{
                    color: Color.greyHighShaded,
                    marginTop: "2vh",
                    textAlign: "center",
                  }}
                >
                  Aucun entretien prévu pour cette machine
                </Typography>
              )}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenMaintenanceForm(true)}
            >
              <AddBoxIcon style={{ color: Color.green }} />
            </IconButton>
            <Typography style={{ color: Color.green }}>
              Ajouter un entretien
            </Typography>
          </div>
        </div>
      </>
    );
  };

  const TabBar = () => {
    const tabBarItems = [
      { key: "details", isDisabled: false, label: "Détails" },
      { key: "bookings", isDisabled: false, label: "Contrats" },
      { key: "estimates", isDisabled: false, label: "Devis" },
      {
        key: "maintenance",
        isDisabled: isMaintenanceDisabled,
        label: "Plan d'entretien",
      },
    ];
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
          borderBottomStyle: "solid",
          borderColor: Color.greyHighShaded,
          borderWidth: 1,
        }}
      >
        <Tabs
          value={tabBarIndex}
          onChange={(event, value) => setTabBarIndex(value)}
          aria-label="checking-tab"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabBarItems?.map(({ key, isDisabled, label }) => (
            <Tab disabled={isDisabled} key={key} label={label} />
          ))}
        </Tabs>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
        >
          <div style={{ display: "flex", gap: "2vw", alignItems: "center" }}>
            {[1, 2].includes(tabBarIndex) ? (
              <div>
                <Tooltip title={`Créer un ${tabBarIndex === 1 ? "contrat" : "devis"}`}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() =>
                      initBooking({
                        state:
                          tabBarIndex === 1
                            ? "contract"
                            : tabBarIndex === 2
                            ? "estimate"
                            : null,
                      })
                    }
                  >
                    <AddBoxIcon style={{ color: Color.green }} />
                  </IconButton>
                </Tooltip>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
              }}
            >
              <Tooltip title={`${Language.contractDetails.copyTooltip} l'id machine`}>
                <ContentCopyIcon
                  size="large"
                  edge="start"
                  aria-label="menu"
                  onClick={() => navigator.clipboard.writeText(_idMachine)}
                  style={{ cursor: "pointer", color: Color.green }}
                />
              </Tooltip>
            </div>
          </div>
        </Box>
      </Box>
    );
  };

  const gridLevels = (label, value) => {
    return (
      <Box key={label} sx={{ paddingLeft: "10px" }}>
        <Typography
          component="p"
          sx={{
            color: Color.grey,
            paddingRight: "15px",
            fontSize: "15px",
          }}
        >
          {label}
          {value !== true && " : "}
          <span style={{ fontWeight: "800" }}>
            {!Array.isArray(value) ? value : ""}
          </span>
        </Typography>
        {Array.isArray(value) && (
          <Table>
            <TableBody
              sx={{
                border: `solid 1px ${Color.greyHighShaded}`,
              }}
            >
              {value.map(({ key, buyIn, levelPrice }, index) => (
                <TableRow key={key}>
                  <TableCell>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      sx={{
                        color: Color.grey,
                        fontSize: "15px",
                      }}
                    >
                      {Language.forfaitLocation.buyIn} {buyIn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="p"
                      sx={{
                        color: Color.grey,
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {levelPrice} {Language.forfaitLocation.priceHours}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    );
  };

  const colDef = [
    {
      field: "contractType",
      headerName: "Type contrat",
      type: "textColumn",
      valueGetter: ({ data }) =>
        localProps?.companyDiscountGrid?.find(
          ({ key }) => key === data.contractType
        )?.label || "LOCATION",
    },
    {
      comparator: (a, b) => {
        const [aDay, aMonth, aYear] = a?.split("/") || [];
        const [bDay, bMonth, bYear] = b?.split("/") || [];
        const aDate = a ? new Date(aYear, aMonth, aDay) : null;
        const bDate = b ? new Date(bYear, bMonth, bDay) : null;
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      },
      field: "createdAt",
      headerName: "Date de création",
      filter: "agDateColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
      valueGetter: ({ data }) => {
        const [date] = new Date(data?.createdAt)
          .toLocaleString("fr-FR", {
            timeZone: "UTC",
          })
          .split(" ");
        return date;
      },
      width: 120,
    },
    {
      field: "createdBy",
      headerName: "Loker",
      type: "textColumn",
      valueGetter: ({ data }) => getMember([data.createdBy]),
      width: 120,
    },
    {
      field: "checker",
      headerName: "Checker",
      type: "textColumn",
      valueGetter: ({ data }) => getMember([data?.checker]),
      width: 120,
    },
    {
      field: "startDay",
      filter: "agDateColumnFilter",
      comparator: (a, b) => {
        const [aDay, aMonth, aYear] = a?.split("/") || [];
        const [bDay, bMonth, bYear] = b?.split("/") || [];
        const aDate = a ? new Date(aYear, aMonth, aDay) : null;
        const bDate = b ? new Date(bYear, bMonth, bDay) : null;
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      },
      filterParams: {
        buttons: ["clear", "apply"],
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;
          const dateParts = dateAsString.split("/");
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      headerName: "Départ",
      valueGetter: ({ data }) => {
        const [date] = new Date(data?.startDay)
          .toLocaleString("fr-FR", {
            timeZone: "UTC",
          })
          .split(" ");
        return date;
      },
      width: 120,
    },
    {
      comparator: (a, b) => {
        const [aDay, aMonth, aYear] = a?.split("/") || [];
        const [bDay, bMonth, bYear] = b?.split("/") || [];
        const aDate = a ? new Date(aYear, aMonth, aDay) : null;
        const bDate = b ? new Date(bYear, bMonth, bDay) : null;
        return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
      },
      field: "stopDay",
      filter: "agDateColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
      headerName: "Retour",
      valueGetter: ({ data }) => {
        const [date] = new Date(data?.stopDay)
          .toLocaleString("fr-FR", {
            timeZone: "UTC",
          })
          .split(" ");
        return date;
      },
    },
    {
      field: "customer",
      headerName: "Client",
      type: "textColumn",
    },
    {
      field: "departureAgency",
      headerName: "Agence départ",
      type: "textColumn",
      width: 120,
    },
    {
      field: "bookingStatus",
      headerName: "Statut",
      type: "textColumn",
      valueGetter: ({ data }) => Language.bookingStatusRenderer?.[data.status],
      width: 120,
    },
    {
      cellRenderer: ({ data }) => {
        const _netPrice = parseFloat(data.netPrice);
        return isNaN(_netPrice) ? null : `${_netPrice.toFixed(2)}€`;
      },
      headerName: "Total HT",
      type: "agNumberColumnFilter",
      valueGetter: ({ data }) => {
        const _netPrice = parseFloat(data.netPrice);
        return isNaN(_netPrice) ? null : _netPrice;
      },
    },
    {
      field: "comment",
      headerName: "Commentaire",
      type: "textColumn",
      width: 350,
    },
  ];
  const rowClassRules = {
    "status-closed": function (params) {
      return params.data.status === "closed";
    },

    "status-invoiced": function (params) {
      return params.data.status === "invoiced";
    },
  };

  useEffect(() => {
    constructor();
  }, []);

  return (
    <>
      <Dialog fullScreen open={isVisible}>
        {localProps.loading ? (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translateY(-50%) translateX(-50%) ",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography
              component={"h2"}
              variant="body1"
              sx={{
                textTransform: "uppercase",
              }}
            >
              {Language.appBar.loading}
            </Typography>
          </Box>
        ) : (
          <>
            {isOpenAddMachine && (
              <AddUpdateMachine
                internIdList={internIdList}
                machineProps={localProps?.machine}
                handleClose={() => setIsOpenAddMachine(false)}
                handleMergeMachine={rebuildMachineList}
                role="updateMachine"
                setSnackBar={(props) => setSnackBar(props)}
              />
            )}
            {isOpenAddUpBook && (
              <AddUpdateBooking
                discountGrid={localProps.companyDiscountGrid}
                handleClose={() => setIsOpenAddUpBook(false)}
                initialBooking={initialBooking}
                role="addBooking"
                setSnackBar={(props) => setSnackBar(props)}
              />
            )}
            {contractDetails !== null && (
              <ContractDetails
                contractDetails={contractDetails}
                discountGrid={localProps.companyDiscountGrid}
                handleMergeBooking={(props) => handleMergeBooking(props)}
                setContractDetails={setContractDetails}
                handleCloseAndRefresh={(props) => handleCloseAndRefresh(props)}
              />
            )}
            <Box>
              <AppBar
                position="static"
                className="bar"
                sx={{ position: "relative", zIndex: "50" }}
              >
                <Toolbar>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() =>
                      setMachineDetails({ data: false, isVisible: false })
                    }
                  >
                    <Close />
                  </IconButton>
                  <Typography>
                    {data
                      ? jsonObject.machine.model
                        ? `${jsonObject.machine.brand} - ${jsonObject.machine.model}`
                        : jsonObject.machine.brand
                      : null}
                  </Typography>
                  <Box sx={{ marginLeft: "auto" }}>
                    {appBarButtonList.map((iconButton) => (
                      <Tooltip title={iconButton.tooltip} key={iconButton.key}>
                        <IconButton
                          disabled={iconButton?.restriction}
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          sx={{ mr: 2 }}
                          onClick={iconButton.onClick}
                        >
                          {iconButton.icon}
                        </IconButton>
                      </Tooltip>
                    ))}
                  </Box>
                </Toolbar>
              </AppBar>
            </Box>
            <TabBar />
            {tabBarIndex === 0 ? (
              <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <AppCarousel
                    sx={{ marginTop: "50px" }}
                    pictures={_pictures}
                  />
                </div>
                <div
                  style={{
                    flex: 2,
                    overflowY: "scroll",
                    height: "100vh",
                    padding: "10px 25px 100px 25px",
                  }}
                >
                  {_dataShow &&
                    localProps.labels &&
                    _dataShow.map(({ detailsGroup, title, details }) =>
                      Object.keys(details)?.length ? (
                        <Fragment key={title}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              color: Color.greyShaded,
                              fontWeight: "900",
                              paddingBottom: "5px",
                              borderBottom: `solid 2px ${Color.greyHighShaded}`,
                              margin: "5px 0px",
                            }}
                          >
                            {title}
                          </Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "50% 50%",
                              gap: "5px",
                            }}
                          >
                            {machineDetailsRender({
                              detailsGroup,
                              details,
                            }).map(({ label, type, value }) => (
                              <Box
                                key={label}
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  component="p"
                                  sx={{
                                    color: Color.grey,
                                    paddingRight: "15px",
                                    fontSize: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {["default", "numeric", "picker"].includes(
                                    type
                                  ) ? (
                                    `${label} : ${value}`
                                  ) : type === "calendarInput" ? (
                                    `${label} : ${new Date(
                                      value?.[0]
                                    )?.toLocaleDateString("fr-FR", {
                                      //weekday: "short",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}`
                                  ) : Array.isArray(value) ? (
                                    value.map(({ label, value }) =>
                                      gridLevels(label, value)
                                    )
                                  ) : (
                                    <>
                                      <DoneIcon sx={{ color: Color.green }} />
                                      {`${label}`}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Fragment>
                      ) : null
                    )}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: Color.greyShaded,
                      fontWeight: "900",
                      paddingBottom: "5px",
                      borderBottom: `solid 2px ${Color.greyHighShaded}`,
                      margin: "5px 0px",
                    }}
                  >
                    {machineDetailsTitle.groups}
                  </Typography>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    {group?.length ? (
                      group.map((_group) => (
                        <Box
                          key={_group}
                          style={{
                            borderRadius: 20,
                            padding: 10,
                            margin: 5,
                            backgroundColor: Color.green,
                          }}
                        >
                          <Typography style={{ color: Color.white }}>
                            {_group}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography>{machineDetailsTitle.noGroup}</Typography>
                    )}
                  </Box>
                </div>
              </div>
            ) : tabBarIndex === 1 ? (
              localProps?.bookingsList?.length ? (
                <div className="ag-theme-grid">
                  <AgGridReact
                    autoSizeStrategy={{ type: "fitGridWidth" }}
                    localeText={AG_GRID_LOCALE_FR}
                    rowData={localProps?.bookingsList}
                    rowClassRules={rowClassRules}
                    domLayout="autoHeight"
                    ref={gridRef}
                    rowStyle={{
                      borderBottomWidth: 1,
                      borderColor: Color.greyShaded,
                      textAlign: "center",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    rowHeight={50}
                    onCellClicked={({ data: { jsonObject } }) =>
                      setContractDetails(jsonObject)
                    }
                    paginationPageSize={100}
                    pagination={true}
                    columnDefs={colDef}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "30vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "0.9em", color: Color.greyShaded }}
                  >
                    Aucun contrat pour cette machine
                  </Typography>
                </div>
              )
            ) : tabBarIndex === 2 ? (
              localProps?.estimatesList?.length ? (
                <div className="ag-theme-grid">
                  <AgGridReact
                    autoSizeStrategy={{ type: "fitGridWidth" }}
                    localeText={AG_GRID_LOCALE_FR}
                    rowData={localProps?.estimatesList}
                    rowClassRules={rowClassRules}
                    domLayout="autoHeight"
                    ref={gridRef}
                    rowStyle={{
                      borderBottomWidth: 1,
                      borderColor: Color.greyShaded,
                      textAlign: "center",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    rowHeight={50}
                    onCellClicked={({ data: { jsonObject } }) =>
                      setContractDetails(jsonObject)
                    }
                    paginationPageSize={100}
                    pagination={true}
                    columnDefs={colDef}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "30vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "0.9em", color: Color.greyShaded }}
                  >
                    Aucun devis pour cette machine
                  </Typography>
                </div>
              )
            ) : tabBarIndex === 3 ? (
              <MaintenancePlan />
            ) : null}
          </>
        )}
      </Dialog>
    </>
  );
}
export default MachineDetails;

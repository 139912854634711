/*global Promise */
import UsersIcon from "@mui/icons-material/PeopleAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useRef, useState } from "react";
import UserApi from "../api/user";
import {
  ActiveRenderer,
  BookingRequirementRender,
  CheckingRequirementRender,
  EmailNotifRenderer,
  LicenceRender,
  RestrictionRenderer,
  UserRenderer
} from "../components/AgGrid/CellRenderer";
import { default as Color, default as Colors } from "../constants/colors.json";
import dataConfig from "../constants/data.json";
import Language from "../constants/language_fr.json";
import UserContext from "../Contexts/UserContext";
import "../styles/ag-theme-alpine/listXgrid.css";
import "../styles/ag-theme-alpine/styles.scss";
import AgGridLocale from "./AgGrid/AG_GRID_LOCALE_FR.json";

export default function TeamManagement({
  userInfo,
  companyInfo,
  members,
  bookingRestrictions,
}) {
  const { checkingRequirements } = dataConfig || {};

  const { user } = useContext(UserContext);

  const [rowData, setRowData] = useState(members);
  const [uploading, setUploading] = useState(false);

  const fileInput = useRef(null);

  const defaultColDef = {
    width: 150,
    sortable: false,
    floatingFilter: false,
    resizable: true,
    flex: 1,
    menuTabs: [],
  };
  const columnDefs = [
    {
      field: "user",
      headerName: Language.headerGrid.userTitle,
      cellRenderer: "userRenderer",
      minWidth: 140,
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
    },
    {
      field: "licence",
      headerName: Language.headerGrid.userLicence,
      suppressMenu: true,
      cellRenderer: "licenceRender",
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 160,
    },
    {
      field: "configuration.emailNotifications",
      headerName: Language.headerGrid.userEmailNotif,
      suppressMenu: true,
      cellRenderer: "emailNotifRenderer",
      valueGetter: (params) => {
        const { emailNotifications } = params?.data?.configuration;
        return emailNotifications ? emailNotifications : {};
      },
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 220,
    },
    {
      field: "configuration.restrictions",
      headerName: Language.headerGrid.restriction,
      suppressMenu: true,
      cellRenderer: "restrictionRenderer",
      valueGetter: (params) => {
        const { restrictions } = params?.data?.configuration || {};
        return restrictions ? restrictions : {};
      },
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 220,
    },
    {
      field: "configuration.requirements.booking",
      headerName: Language.headerGrid.bookingRequirements,
      suppressMenu: true,
      cellRenderer: "bookingRequirementRender",
      valueGetter: (params) => {
        const { booking } = params?.data?.configuration?.requirements || {};
        return booking ? booking : {};
      },
      cellRendererParams: {
        bookingRestrictions: bookingRestrictions,
      },
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 220,
    },
    {
      field: "configuration.requirements.checking",
      headerName: Language.headerGrid.checkingRequirements,
      suppressMenu: true,
      cellRenderer: "checkingRequirementRender",
      valueGetter: (params) => {
        const { checking } = params?.data?.configuration?.requirements || {};
        return checking ? checking : {};
      },
      cellRendererParams: {
        checkingRequirements: checkingRequirements,
      },
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 220,
    },
    {
      field: "isActive",
      headerName: Language.headerGrid.userActive,
      suppressMenu: true,
      cellRenderer: "activeRenderer",
      cellStyle: (params) => {
        return userInfo.licence === "manager"
          ? ""
          : { "pointer-events": "none", opacity: "0.4" };
      },
      minWidth: 110,
    },
  ];
  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const handleUploadXls = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    var extension = file.name.split(".").pop().toLowerCase();
    var allowed = ["xls", "xlsx"];

    if (allowed.includes(extension)) {
      const formData = new FormData();
      formData.append("filedata", file);
      await UserApi.importFromXls({ formData, token: user.token });
      setUploading(false);
      fileInput.current.value = null;
    } else {
      setUploading(false);
      fileInput.current.value = null;
    }
  };

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          style={{ color: Color.green, marginTop: "25px" }}
        >
          <UsersIcon style={{ color: "green", marginRight: "15px" }} />
          {companyInfo.name}
        </Typography>
        {user.licence === "manager" ? (
          <>
            <Tooltip title={Language.headerGrid.uploadUserFile}>
              <UploadFileIcon
                style={{
                  color: uploading ? Colors.greyShaded : Colors.green,
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                onClick={() => fileInput?.current?.click()}
              />
            </Tooltip>
            <input
              type="file"
              onChange={handleUploadXls}
              accept=".xlsx, .xls"
              ref={fileInput}
              style={{ display: "none" }}
            />
          </>
        ) : null}
      </Box>
      <Divider />
      <Grid container>
        <Grid item xs={12} lg={12}>
          <div
            id="myGrid"
            role="grid"
            className="ag-theme-alpine"
            style={{ height: "90vh", width: "100%" }}
          >
            <AgGridReact
              localeText={AgGridLocale}
              rowData={rowData}
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowStyle={{
                borderBottomWidth: 1,
                textAlign: "left",
              }}
              rowHeight={160}
              suppressCellSelection={true}
              onFirstDataRendered={onFirstDataRendered}
              columnDefs={columnDefs}
              frameworkComponents={{
                userRenderer: UserRenderer,
                licenceRender: LicenceRender,
                emailNotifRenderer: EmailNotifRenderer,
                restrictionRenderer: RestrictionRenderer,
                bookingRequirementRender: BookingRequirementRender,
                checkingRequirementRender: CheckingRequirementRender,
                activeRenderer: ActiveRenderer,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
